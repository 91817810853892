import React from 'react'

export const LikeButton = ({ 
    className, 
    onClick, 
    img, 
    width, 
    height,
    title
    }) => {
    return (
        <button className={className} onClick={onClick} title={title}>
            <img src={img} alt={"Like Button"} width={width} height={height}/>
        </button>
    )
}