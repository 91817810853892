import React from 'react'
import { Row } from './Row'

export const Liked = ({ 
    Library,
    reload,
    handleSelected,
    handleLiked
    }) => {
    return (
        <section className='Liked'>
            {Library.map(Ost => {
                if (Ost.liked) {
                    return (
                        <Row 
                            Library={Ost} 
                            reload={reload}
                            img={Ost.coverArt} 
                            title={Ost.title} 
                            handleSelected={handleSelected}
                            handleLiked={handleLiked}
                        />
                    )
                }
                return(null)
                })}
        </section>
    )
}
