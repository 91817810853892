import React from 'react'

export const InfoBox = ({
    selectedSong
    }) => {
    return (
        <div className='InfoBox'>
            <p className='Released'>Released:</p>
            <p className='ReleaseDate'>{selectedSong.releaseDate}</p>
            <p className='Composers'>Composers:</p>
            {selectedSong.composer.map(composer => {
                return (
                    <li className='ComposerList'>{composer}</li>
                )
            })}
        </div>
    )
}
