import React from 'react'
import { Row } from './Row'

export const Library = ({ 
    Library,
    reload,
    handleSelected,
    handleLiked
    }) => {
    return (
    <section className='Library'>
        {Library.map(Ost => {
            return (
                <Row 
                    Library={Ost} 
                    reload={reload}
                    img={Ost.coverArt} 
                    title={Ost.title} 
                    handleSelected={handleSelected}
                    handleLiked={handleLiked}
                />
        )})}
    </section>
    )
}
