import './App.css';
import React, { useState, useEffect } from 'react'
import { Button } from './components/Button';
import { LikeButton } from './components/LikeButton';
import { Library } from './components/Library';
import { Grid } from './components/Grid';
import { Liked } from './components/Liked';
import { InfoBox } from './components/InfoBox';
import YoutubeEmbed from './components/YoutubeEmbed';
// =========================== Icons ======================================== 
import DiceIcon from './icons/DiceIcon.png'
import HamburgerIcon from './icons/HamburgerIcon.png'
import GridIcon from './icons/GridIcon.png'
import AlphabetFirstIcon from './icons/AlphabetFirstIcon.png'
import AlphabetLastIcon from './icons/AlphabetLastIcon.png'
import AlphabetNoneIcon from './icons/AlphabetNoneIcon .png'
import DateFirstIcon from './icons/DateFirstIcon.png'
import DateLastIcon from './icons/DateLastIcon.png'
import DateNoneIcon from './icons/DateNoneIcon.png'
import SettingsIcon from './icons/SettingsIcon.png'
import InfoIcon from './icons/InfoIcon.png'
import EmptyLike from './icons/EmptyLike.png'
import FilledLike from './icons/FilledLike.png'
import TitleIcon from "./icons/TitleIcon.png"
import ComposerIcon from "./icons/ComposerIcon.png"
import ReleaseDateIcon from "./icons/ReleaseDateIcon.png"
// ========================= Covers ========================================= 
import BoIAntibirth from './images/BoIAntibirth.jpg'
import FFXIV from './images/FFXIV.jpg'
import Yakuza0 from './images/Yakuza0.jpg'
import HollowKnight from "./images/HollowKnight.jpg"
import Celeste from "./images/Celeste.jpg"
import HyperLightDrifter from "./images/HyperLightDrifter.jpg"
import OriBlindForest from "./images/OriBlindForest.jpg"
import SuperMarioGalaxy from "./images/SuperMarioGalaxy.jpg"
import SuperMarioGalaxy2 from "./images/SuperMarioGalaxy2.jpg"
import SuperMarioSunshine from "./images/SuperMarioSunshine.jpg"
import OuterWilds from "./images/OuterWilds.jpg"
import Hades from "./images/Hades.jpg"
import Minecraft from "./images/Minecraft.jpg"
import ACNH from "./images/AnimalCrossingNH.jpg"
import Pikmin2 from "./images/Pikmin2.jpg"
import SuperMarioOdyssey from "./images/SuperMarioOdyssey.jpg"
import BOTW from "./images/BOTW.jpg"
import DarkSouls from "./images/DarkSouls.jpeg"
import SAB2 from "./images/SAB2.jpg"
import Skyrim from "./images/Skyrim.jpg"
import Pikuniku from "./images/Pikuniku.jpg"
import Cuphead from "./images/Cuphead.jpg"
import Terraria from "./images/Terraria.jpg"
import SWJFO from "./images/SWJFO.jpg"
import DoomEternal from "./images/DoomEternal.jpg"
import OOT from "./images/OOT.jpg"
import Undertale from "./images/Undertale.jpg"
import Halo2 from "./images/Halo2.jpg"
import Witcher3 from "./images/Witcher3.jpg"
import Spore from "./images/Spore.jpg"
import StardewValley from "./images/StardewValley.jpg"
import Sable from "./images/Sable.jpg"
import OSRS from "./images/OSRS.jpg"
import WOW from "./images/WOW.jpg"
import Divinity2 from "./images/Divinity2.jpg"
import DiabloIV from "./images/DiabloIV.jpg"
import PokemonFRLG from "./images/PokemonFRLG.jpg"
import TWW from "./images/TWW.jpg"
import deBlob from "./images/deBlob.jpg"
import LEGOSWSS from "./images/LEGOSWSS.jpg"
import MarioKart8 from "./images/MarioKart8.jpg"
import Bastion from "./images/Bastion.jpg"
import AHatInTime from "./images/AHatInTime.jpg"
import SuperMario64 from "./images/SuperMario64.jpg"
import LEGOLOTR from "./images/LEGOLOTR.jpg"
import CaveStory from "./images/CaveStory.jpg"
import Wynncraft from "./images/Wynncraft.jpeg"
import Bugsnax from "./images/Bugsnax.jpeg"
import EldenRing from "./images/EldenRing.jpeg"
import SuperSmashBrosMelee from "./images/SuperSmashBrosMelee.jpeg"
import PillarsofEternity from "./images/PillarsofEternity.webp"
import DarkSouls2 from "./images/DarkSouls2.jpeg"
import DarkSouls3 from "./images/DarkSouls3.jpeg"
import HogwartsLegacy from "./images/HogwartsLegacy.jpeg"
import DRG from "./images/DRG.webp"
import LOTRO from "./images/LOTRO.jpeg"
import Valheim from "./images/Valheim.jpeg"
import RiskofRain2 from "./images/RiskofRain2.webp"
import Pathway from "./images/Pathway.jpeg"
import Civ6 from "./images/Civ6.jpeg"
import Civ5 from "./images/Civ5.jpeg"
import Civ4 from "./images/Civ4.png"
import SandS from "./images/SandS.jpeg"
import Rimworld from "./images/Rimworld.jpeg"
import DwarfFortress from "./images/DwarfFortress.jpeg"
import TwilightPrincess from "./images/TwilightPrincess.jpeg"
import GodofWar from "./images/GodofWar.jpg"
import Franken from "./images/Franken.jpg"
import ChronoTrigger from "./images/ChronoTrigger.jpg"
import ALTTP from "./images/ALTTP.jpeg"
import TLOZ from "./images/TLOZ.jpeg"
import ZeldaII from "./images/ZeldaII.png"
import MajorasMask from "./images/MajorasMask.jpeg"
import TOTK from "./images/TOTK.jpeg"
import SuperMario3DWorld from "./images/SuperMario3dWorld.webp"
import NewSuperMarioBrosWii from "./images/NewSuperMarioBrosWii.png"
import BillyHatcher from "./images/BillyHatcher.jpeg"
import DisneysExtremeSkateAdventure from "./images/DisneysExtremeSkateAdventure.jpeg"
import COTND from "./images/COTND.jpg"
import GRIS from "./images/GRIS.jpg"
import OMORI from "./images/OMORI.jpg"
import MonsterSanctuary from "./images/MonsterSanctuary.jpg"
import Portal from "./images/Portal.webp"
import Portal2 from "./images/Portal2.webp"
import RainWorld from "./images/RainWorld.jpg"
import SLUDGELIFE2 from "./images/SLUDGELIFE2.jpg"
import OriWillWisps from "./images/OriWillWisps.jpg"
import Blasphemous from "./images/Blasphemous.jpg"
import Morrowind from "./images/Morrowind.jpg"
import Oblivion from "./images/Oblivion.jpg"
import SuperSmashBroBrawl from "./images/SuperSmashBroBrawl.jpg"
import SuperSmashBros from "./images/SuperSmashBros.jpeg"
import SuperSmashBrosUltimate from "./images/SuperSmashBrosUltimate.jpg"
import MarioKartDoubleDash from "./images/MarioKartDoubleDash.jpg"
import KirbyAirRide from "./images/KirbyAirRide.jpg"
import Unrailed from "./images/Unrailed.jpg"
import HPQWC from "./images/HPQWC.jpg"
import A1080 from "./images/A1080.jpg"
import SWBF2C from "./images/SWBF2C.jpg"
import FEZ from "./images/FEZ.jpg"
import N from "./images/N.jpg"
import Overcooked from "./images/Overcooked.webp"
import PapersPlease from "./images/PapersPlease.jpg"
import PizzaTower from "./images/PizzaTower.jpg"
import MidnightClub3 from "./images/MidnightClub3.jpg"
import DungeonSiege from "./images/DungeonSiege.jpg"
import PokemonGVSV from "./images/PokemonGVSV.jpg"
import ROTMG from "./images/ROTMG.jpg"
import HuntShowdown from "./images/HuntShowdown.jpg"
import Persona5 from "./images/Persona5.jpg"
import VampireSurvivors from "./images/VampireSurvivors.jpg"
import DungeonDefenders from "./images/DungeonDefenders.jpg"
import LuigisMansion from "./images/LuigisMansion.jpg"
import PJ1 from "./images/PJ1.jpg"
import KirbyNIDL from "./images/KirbyNIDL.jpg"
import GTAV from "./images/GTAV.jpg"
import Doom2016 from "./images/Doom2016.jpg"
import HarryPotterATSS from "./images/HarryPotterATPS.jpg"
import AmongUs from "./images/AmongUs.jpg"
import GeometryDash from "./images/GeometryDash.jpg"
import TitanSouls from "./images/TitanSouls.jpg"
import Destiny2 from "./images/Destiny2.jpg"
import CODMW2 from "./images/CODMF2.jpg"
import NeverwinterKnights from "./images/NeverwinterKinghts.jpg"
import Kahoot from "./images/Kahoot.jpg"
import MGSV from "./images/MGSV.jpg"
import Planetside2 from "./images/Planetside2.jpg"
import GuitarHero2 from "./images/GuitarHero2.jpg"
import MirrorsEdge from "./images/MirrorsEdge.jpg"
import Wilkanoid from "./images/Wilkanoid.png"
import ChexQuest from "./images/ChexQuest.jpg"
import Halo from "./images/Halo.jpg"
import Halo3 from "./images/Halo3.png"
import BF1 from "./images/BF1.jpg"
import BF3 from "./images/BF3.jpg"
import BF4 from "./images/BF4.jpg"
import MassEffect from "./images/MassEffect.webp"
import MassEffect2 from "./images/MassEffect2.jpg"
import MassEffect3 from "./images/MassEffect3.jpg"
import MafiaII from "./images/MafiaII.webp"
import SonicGenerations from "./images/SonicGenerations.jpeg"
import Sonic06 from "./images/Sonic06.jpg"
import BugFables from "./images/BugFables.jpg"
import PMTYD from "./images/PMTYD.jpg"
import StarcraftII from "./images/StarcraftII.jpg"
import Overwatch from "./images/Overwatch.jpg"
import Overwatch2 from "./images/Overwatch2.jpg"
import Hearthstone from "./images/Hearthstone.jpg"
import ChibiRobo from "./images/ChibiRobo.jpg"
import WiiSports from "./images/WiiSports.jpg"
import WiiPlay from "./images/WiiPlay.jpg"
import MarbleBlastGold from "./images/MarbleBlastGold.jpg"
import RockofAges from "./images/RockofAges.jpg"
import HOTS from "./images/HOTS.jpg"
import Brawlhalla from "./images/Brawlhalla.jpg"
import HalfLife from "./images/HalfLife.jpg"
import HalfLife2 from "./images/HalfLife2.jpg"
import HalfLifeAlyx from "./images/HalfLifeAlyx.jpg"
import BaldursGate from "./images/BaldursGate.jpg"
import BaldursGate2 from "./images/BaldursGate2.jpg"
import BaldursGate3 from "./images/BaldursGate3.jpg"
import LittleNightmares from "./images/LittleNightmares.jpg"
import LittleNightmaresII from "./images/LittleNightmaresII.jpg"
import SunsetOverdrive from "./images/SunsetOverdrive.jpg"
import SouthParkTSOT from "./images/SouthParkTSOT.jpg"
import Journey from "./images/Journey.jpg"
import HotlineMiami from "./images/HotlineMiami.jpg"
import EarthBound from "./images/EarthBound.jpg"
import DKCountry from "./images/DKCountry.jpg"
import DKCountry2 from "./images/DKCountry2.jpg"
import DKCountry3 from "./images/DKCountry3.jpg"
import Bioshock from "./images/Bioshock.jpg"
import BioshockInfinite from "./images/BioshockInfinite.jpg"
import MedalofHonor from "./images/MedalofHonor.jpg"
import LANoire from "./images/LANoire.jpg"
import EGTTR from "./images/EGTTR.jpg"
import MonumentValley2 from "./images/MonumentValley2.jpg"
import GrandFantasia from "./images/GrandFantasia.jpg"
import TLOU from "./images/TLOU.jpg"
import TLOUP2 from "./images/TLOUP2.jpg"
import KatamariDamacy from "./images/KatamariDamacy.jpg"
import NieRAutomata from "./images/NieRAutomata.jpg"
import Transistor from "./images/Transistor.jpg"
import DeadlyPremonition from "./images/DeadlyPremonition.jpg"
import SOTC from "./images/SOTC.jpg"
import HorizonZeroDawn from "./images/HorizonZeroDawn.jpg"
import HorizonForbiddenWest from "./images/HorizonForbiddenWest.jpg"
import RocketLeague from "./images/RocketLeague.jpg"
import DiscoElysium from "./images/DiscoElysium.jpg"
import RDR from "./images/RDR.jpg"
import RDR2 from "./images/RDR2.jpg"
import LifeisStrange from "./images/LifeisStrange.jpg"
import Bloodborne from "./images/Bloodborne.jpg"
import Sekiro from "./images/Sekiro.jpg"
import JetSetRadio from "./images/JetSetRadio.jpg"
import NBASV3 from "./images/NBASV3.png"
import MetalHellsinger from "./images/MetalHellsinger.jpg"
import SSXTricky from "./images/SSXTricky.jpg"
import Robocraft from "./images/Robocraft.jpg"
import Okami from "./images/Okami.png"
import DeathStranding from "./images/DeathStranding.jpg"
import PvsZ from "./images/PvsZ.jpg"
import ProfLayton from "./images/ProfLayton.jpg"
import ShovelKnight from "./images/ShovelKnight.jpg"
import CrashBandicoot from "./images/CrashBandicoot.jpg"
import RaymanLegends from "./images/RaymanLegends.jpg"
import StarFox64 from "./images/StarFox64.webp"
import KirbySS from "./images/KirbySS.jpg"
import FTL from "./images/FTL.jpg"
import GrimFandango from "./images/GrimFandango.png"
import FZeroX from "./images/FZeroX.jpg"
import SecretofMana from "./images/SecretofMana.jpg"
import Ico from "./images/Ico.webp"
import Dishonored from "./images/Dishonored.jpg"
import Knack from "./images/Knack.webp"
import KnackII from "./images/KnackII.webp"
import CultoftheLamb from "./images/CultoftheLamb.jpg"
import Stray from "./images/Stray.webp"
import Furi from "./images/Furi.jpg"
import RiptideRenegade from "./images/RiptideRenegade.jpg"
import WWSM from "./images/WWSM.jpg"
import DemonsSouls from "./images/DemonsSouls.jpg"
import ROTOD from "./images/ROTOD.jpg"
import RampageTD from "./images/RampageTD.jpg"
import TUNIC from "./images/TUNIC.jpg"
import DAOG from "./images/DAOG.jpg"
import DA2 from "./images/DA2.webp"
import DAI from "./images/DAI.jpg"
import Pikmin from "./images/Pikmin.png"
import Pikmin3 from "./images/Pikmin3.jpg"
import Pikmin4 from "./images/Pikmin4.jpg"
import Diablo from "./images/Diablo.jpg"
import DiabloII from "./images/DiabloII.jpg"
import DiabloIII from "./images/DiabloIII.jpg"
import DiabloImmortal from "./images/DiabloImmortal.jpg"
import WOWBC from "./images/WOWBC.jpg"
import WOWWOTLK from "./images/WOWWOTLK.jpg"
import WOWC from "./images/WOWC.jpg"
import WOWMOP from "./images/WOWMOP.jpg"
import WOWWOD from "./images/WOWWOD.jpg"
import WOWL from "./images/WOWL.jpg"
import WOWBFA from "./images/WOWBFA.jpg"
import WOWS from "./images/WOWS.jpg"
import WOWD from "./images/WOWD.jpg"
import AC from "./images/AC.jpg"
import ACWW from "./images/ACWW.jpg"
import ACNL from "./images/ACNL.jpg"
import UnchartedDF from "./images/UnchartedDF.jpg"
import Uncharted2AT from "./images/Uncharted2AT.jpg"
import Uncharted3DD from "./images/Uncharted3DD.jpg"
import Uncharted4ATE from "./images/Uncharted4ATE.jpg"
import KH from "./images/KH.jpg"
import KH2 from "./images/KH2.webp"
import KH3 from "./images/KH3.webp"
import HitmanC47 from "./images/HitmanC47.jpg"
import Hitman from "./images/Hitman1.webp"
import Hitman2 from "./images/Hitman2.jpg"
import Hitman3 from "./images/Hitman3.jpg"
import Arena from "./images/Arena.jpg"
import Daggerfall from "./images/Daggerfall.webp"
import LOL from "./images/LOL.jpg"
import TheMessenger from "./images/TheMessenger.png"
import FFXIVHeavensward from "./images/FFXIVHeavensward.jpg"
import FFXIVShadowbringers from "./images/FFXIVShadowbringers.jpg"
import FFXIVStormblood from "./images/FFXIVStormblood.webp"
import FFXIVEndwalker from "./images/FFXIVEndwalker.jpg"
import Skullgirls from "./images/Skullgirl.jpg"
import HotlineMiami2 from "./images/HotlineMiami2.jpg"
import MGRR from "./images/MGRR.webp"
import DevilMayCry5 from "./images/DevilMayCry5.jpg"
import Wildfrost from "./images/Wildfrost.jpg"
import DeadCells from "./images/DeadCells.jpg"
import TF2 from "./images/TF2.jpg"
import EnterTheGungeon from "./images/EnterTheGungeon.jpg"
import Mother3 from "./images/Mother3.jpg"

/**
 * Json array that holds all osts in on the website
 */
const baseDatabase = [
  {
    title: 'Cave Story',
    composer: ['Daisuke "Pixel" Amaya', 'Yann van der Cruyssen', 'Nicklas Nygren', 'Danny Baranowsky', 'Dustin Kulwicki Ridiculon'],
    releaseDate: '2004/12/20',
    coverArt: CaveStory,
    link: 'PL6PHQCxAqpJRdygu_PBTa_iUBmDfppwgz',
    liked: false
  }, 
  {
    title: 'Minecraft',
    composer: ['C418', 'Lena Raine', 'Kumi Tanioka'],
    releaseDate: '2011/11/18',
    coverArt: Minecraft,
    link: 'PLefKpFQ8Pvy5aCLAGHD8Zmzsdljos-t2l',
    liked: false
  }, 
  {
    title: 'Hollow Knight',
    composer: ['Christopher Larkin'],
    releaseDate: '2017/02/24',
    coverArt: HollowKnight,
    link: 'PLmOldskd2VbL7_t-NE9p6rEboq_v0AHko',
    liked: false
  },
  {
    title: 'The Binding of Isaac: Antibirth',
    composer: ['Abhijit "Mudeth" Shylanath'],
    releaseDate: '2016/12/20',
    coverArt: BoIAntibirth,
    link: 'PLrWR7klg98H0DbavfBz66OJO_E3wxKVSZ',
    liked: false
  },
  {
    title: 'Final Fantasy XIV: A Realm Reborn',
    composer: ['Masayoshi Soken', 'Nobuo Uematsu', 'Naoshi Mizuta', 'Tsuyoshi Sekito'],
    releaseDate: '2013/08/27',
    coverArt: FFXIV,
    link: 'OLAK5uy_m8sjPWdifVNP7KAR28DeY4la1AnEj_Y1I',
    liked: false
  },
  {
    title: 'Yakuza 0',
    composer: ['Hidenori Shoji'],
    releaseDate: '2015/03/12',
    coverArt: Yakuza0,
    link: 'PLfp29KYn6RxqSmzl1R3ub8ym9TJZaUWcf',
    liked: false
  },
  {
    title: 'Outer Wilds',
    composer: ['Andrew Prahlow'],
    releaseDate: '2019/05/28',
    coverArt: OuterWilds,
    link: 'OLAK5uy_lvIXOLFb_NVEjnyhZNE66G8O_oeF9IRII',
    liked: false
  },
  {
    title: 'Hyper Light Drifter',
    composer: ['Richard "Disasterpeace" Vreeland'],
    releaseDate: '2016/03/31',
    coverArt: HyperLightDrifter,
    link: 'OLAK5uy_lmS7LJoAlfmtzhFNygQUu7k5yizxlc3l0',
    liked: false
  },
  {
    title: 'A Hat In Time',
    composer: ['Pascal Michael Stiefel', 'Grant Kirkhope'],
    releaseDate: '2017/10/05',
    coverArt: AHatInTime,
    link: 'PLAeP_W1RI8JHKosqaCKsNreqGt5HSVd1V',
    liked: false
  },
  {
    title: 'Bugsnax',
    composer: [' Seth Parker'],
    releaseDate: '2020/11/12',
    coverArt: Bugsnax,
    link: 'PLHkVJ-AxSzV_hmHBbOmpq1GU6Lrfsua1C',
    liked: false
  },
  {
    title: 'Celeste',
    composer: ['Lena Raine'],
    releaseDate: '2018/01/24',
    coverArt: Celeste,
    link: 'PLe1jcCJWvkWiWLp9h3ge0e5v7n6kxEfOG',
    liked: false
  },
  {
    title: 'Super Smash Bros. Melee',
    composer: ['Hirokazu Ando', 'Shogo Sakai', 'Tadashi Ikegami', 'Takuto Kitsuta'],
    releaseDate: '2001/11/21',
    coverArt: SuperSmashBrosMelee,
    link: 'PLAs1Kha_R9dIx9NZd_S80z4m_V2tT9OPb',
    liked: false
  },
  {
    title: 'The Legend of Zelda: Twilight Princess',
    composer: ['Toru Minegishi', 'Asuka Ota'],
    releaseDate: '2006/11/19',
    coverArt: TwilightPrincess,
    link: 'PLAs1Kha_R9dLkWgTjRNXxCJC4YvVycF33',
    liked: false
  },
  {
    title: 'Diablo IV',
    composer: ['Ted Reedy'],
    releaseDate: '2023/06/05',
    coverArt: DiabloIV,
    link: 'PLjyLjyXJQJUO5hx3zwOHXeQ6MJ6_aQ0QW',
    liked: false
  },
  {
    title: 'Hades',
    composer: ['Darren Korb'],
    releaseDate: '2018/12/06',
    coverArt: Hades,
    link: 'PL53mjgVKFq7yu0LdAvpp42ZGLzRCkFKuz',
    liked: false
  },
  {
    title: 'Super Mario Odyssey',
    composer: ['Naoto Kubo'],
    releaseDate: '2017/10/27',
    coverArt: SuperMarioOdyssey,
    link: 'PLAs1Kha_R9dL7tXyXlTWlcn0GlLHl4uuw',
    liked: false
  },
  {
    title: 'Lord of the Rings Online',
    composer: ['Stephen DiGregorio', 'Chance Thomas'],
    releaseDate: '2007/04/24',
    coverArt: LOTRO,
    link: 'PLbxtkil6Zj3iW_t-T9izgrEu-YG7AZxKH',
    liked: false
  },
  {
    title: 'DOOM Eternal',
    composer: ['Mick Gordon'],
    releaseDate: '2020/03/20',
    coverArt: DoomEternal,
    link: 'PL9qahmMHuxzSpTHbPNJfLlLN1HpzKNH2A',
    liked: false
  },
  {
    title: 'Pokemon Fire Red/Leaf Green',
    composer: ['Go Ichinose'],
    releaseDate: '2004/01/29',
    coverArt: PokemonFRLG,
    link: 'PLUXEBUs17WcSTXAd_ugvhq7sZvkI91nnS',
    liked: false
  },
  {
    title: 'Dwarf Fortress',
    composer: ['Dabu', 'Simon Swerwer', 'Águeda Macias'],
    releaseDate: '2006/12/06',
    coverArt: DwarfFortress,
    link: 'PLp1Y6qRhEmZyEeE3O1xz-qb62htJbPqIF',
    liked: false
  },
  {
    title: 'World of Warcraft: Vanilla',
    composer: ['Jason Hayes', 'Tracy W. Bush', 'Derek Duke', 'Glenn Stafford'],
    releaseDate: '2004/11/23',
    coverArt: WOW,
    link: 'PLdSUTU0oamrzflitm5wLvphEMVbsZwt7L',
    liked: false
  },
  {
    title: 'Civilization V',
    composer: ['Geoff Knorr', 'Michael Curran'],
    releaseDate: '2010/09/21',
    coverArt: Civ5,
    link: 'PLhhuipP2X053HE1trv2sD--pw8zOyaknQ',
    liked: false
  },
  {
    title: 'LEGO Lord of the Rings',
    composer: ['Howard Shore', 'Rob Westwood'],
    releaseDate: '2012/10/30',
    coverArt: LEGOLOTR,
    link: 'PLO4jlmGoc6uDNVWLEnQHbEt75TI5fOy8w',
    liked: false
  },
  {
    title: 'Bastion',
    composer: ['Darren Korb'],
    releaseDate: '2011/07/20',
    coverArt: Bastion,
    link: 'PL53mjgVKFq7yPfCiSJaA_aI2SPKrnEmmJ',
    liked: false
  },
  {
    title: 'Cuphead',
    composer: ['Kristofer Maddigan'],
    releaseDate: '2017/09/29',
    coverArt: Cuphead,
    link: 'PLocQeghkorOWXJ3tu8YwNfbwjxTJ2UZWV',
    liked: false
  },
  {
    title: 'Super Mario Galaxy',
    composer: ['Koji Kondo'],
    releaseDate: '2007/11/01',
    coverArt: SuperMarioGalaxy,
    link: 'PLsbXPXPjpUeqjRLpUt9bKqfUFqwG8-ujt',
    liked: false
  },
  {
    title: 'Civilizatiuon IV',
    composer: ['Christopher Tin', 'Bach', 'John Adams', 'many more'],
    releaseDate: '2005/10/25',
    coverArt: Civ4,
    link: 'PL148A567BB5EB0821',
    liked: false
  },
  {
    title: 'Civilizatiuon VI',
    composer: ['Christopher Tin', 'Geoff Knorr', 'Roland Rizzo', 'Tim Wynn'],
    releaseDate: '2016/10/21',
    coverArt: Civ6,
    link: 'PLh4Eme5gACZGDVGh-unzj0p70NiADSdY0',
    liked: false
  },
  {
    title: 'The Legend of Zelda: Ocarina of Time',
    composer: ['Koji Kondo'],
    releaseDate: '1998/11/21',
    coverArt: OOT,
    link: 'PLAs1Kha_R9dLUSxTSb4wLHen4MAQDRIZ_',
    liked: false
  },
  {
    title: 'Pillars of Eternity',
    composer: ['Justin Bell'],
    releaseDate: '2015/03/26',
    coverArt: PillarsofEternity,
    link: 'PL0w2QA7IXPn7g2Bu3IQ2vt2MTLfJbGEpg',
    liked: false
  },
  {
    title: 'Divinity 2: Original Sin',
    composer: ['Borislav Slavov', 'Kirill Pokrovsky'],
    releaseDate: '2017/09/14',
    coverArt: Divinity2,
    link: 'PLuX1dXf1Zu0k0JpNCoN-AealD5q-6CkNg',
    liked: false
  },
  {
    title: 'Hogwarts Legacy',
    composer: ['Peter Murray', 'J. Scott Rakozy', 'Alexander Horowitz', 'Chuck Myers'],
    releaseDate: '2023/02/10',
    coverArt: HogwartsLegacy,
    link: 'PLBKadB95sF47AC0QgG8Eiuc8qaPtoJ6KA',
    liked: false
  },
  {
    title: 'The Legend of Zelda: Breath of the Wild',
    composer: ['Manaka Kataoka', 'Yasuaki Iwata', 'Hajime Wakai'],
    releaseDate: '2017/03/03',
    coverArt: BOTW,
    link: 'PLqrlkIVyDLs-UMeSWlObux9TUJXfxHRLY',
    liked: false
  },
  {
    title: 'Super Mario Sunshine',
    composer: ['Koji Kondo, Shinobu Tanaka'],
    releaseDate: '2002/07/19',
    coverArt: SuperMarioSunshine,
    link: 'PLNvQcJ1tQe2T8qxBtUpkaYRY9j_pK9wsJ',
    liked: false
  },
  {
    title: 'Pathway',
    composer: ['Gavin Harrison'],
    releaseDate: '2019/04/11',
    coverArt: Pathway,
    link: 'OLAK5uy_ky-q99SxcS2EEcyfnK5MvqfFdsipWVfMI',
    liked: false
  },
  {
    title: 'Pikmin 2',
    composer: ['Hajime Wakai'],
    releaseDate: '2004/04/29',
    coverArt: Pikmin2,
    link: 'PLwyW5mbdZMGN8mGTqvDhsBs37SW4TkHcw',
    liked: false
  },
  {
    title: 'Mario Kart 8',
    composer: ['Shiho Fujii', 'Ryo Nagamatsu', 'Asuko Asahi', 'Yasuaki Iwata', 'Kenta Nagata'],
    releaseDate: '2014/05/29',
    coverArt: MarioKart8,
    link: 'PL2XjmdkuVL-04v0wkzgB5vxpyI1Rcv_Dw',
    liked: false
  },
  {
    title: 'Wynncraft',
    composer: ['Salted'],
    releaseDate: '2013/04/29',
    coverArt: Wynncraft,
    link: 'PLyqkjDCr8kbI3CjNZimiri8shU1GbfJ6E',
    liked: false
  },
  {
    title: 'Sable',
    composer: ['Japanese Breakfast'],
    releaseDate: '2021/09/23',
    coverArt: Sable,
    link: 'PL8UbYpPIoqN0AZXBeFeXYw6X5r68A4UgO',
    liked: false
  },
  {
    title: 'Stardew Valley',
    composer: ['Eric Barone'],
    releaseDate: '2016/02/26',
    coverArt: StardewValley,
    link: 'PLKDOdCjxOjzIFucHobwJpSK4-vAVXST90',
    liked: false
  },
  {
    title: 'Terraria',
    composer: ['Scott Lloyd Shelly'],
    releaseDate: '2011/05/16',
    coverArt: Terraria,
    link: 'PLbJE2f8Bl_gRq7g69Z4xfHV7Je-WWvpdJ',
    liked: false
  },
  {
    title: 'LEGO Star Wars: The Skywalker Saga',
    composer: ['Simon Withenshaw'],
    releaseDate: '2022/04/05',
    coverArt: LEGOSWSS,
    link: 'PLzZ7snKQ66PV51b3RrS0P4TCdrqJUnvgp',
    liked: false
  },
  {
    title: 'God of War',
    composer: ['Bear McCreary'],
    releaseDate: '2018/04/20',
    coverArt: GodofWar,
    link: 'OLAK5uy_nWbRrBVDIDM_08DLg_u1uagBWoHdPQS5k',
    liked: false
  },
  {
    title: 'Ori and the Blind Forest',
    composer: ['Gareth Coker'],
    releaseDate: '2015/03/11',
    coverArt: OriBlindForest,
    link: 'PLxbIk89I7mKuYQcurk0mK-h2Fo_aR1ss7',
    liked: false
  },
  {
    title: 'The Legend of Zelda: The Wind Waker',
    composer: ['Kenta Nagata', 'Hajime Wakai', 'Toru Minegishi', 'Koji Kondo'],
    releaseDate: '2002/12/13',
    coverArt: TWW,
    link: 'PLH834K1pkcjJRwrw6j-Ah3FL48SDAsK_z',
    liked: false
  },
  {
    title: 'Spore',
    composer: ['Brian Eno'],
    releaseDate: '2008/09/04',
    coverArt: Spore,
    link: 'PLge4HQ9R9KIFFxQX-2SCX3D1ShcmlOLzj',
    liked: false
  },
  {
    title: 'Halo 2',
    composer: ['Michael Salvatori, Martin ODonnell'],
    releaseDate: '2004/11/09',
    coverArt: Halo2,
    link: 'PL5JoTnRooTtdWiDZbwxwBQRMvP0R1UYOC',
    liked: false
  },
  {
    title: 'Salt and Sanctuary',
    composer: ['James Silva and Michelle Silva'],
    releaseDate: '2016/03/15',
    coverArt: SandS,
    link: 'PL7YuVu11Ud-IAUHrjsXwBFBGeJyxH64EA',
    liked: false
  },
  {
    title: 'Dark Souls 2',
    composer: ['Motoi Sakuraba'],
    releaseDate: '2014/03/11',
    coverArt: DarkSouls2,
    link: 'PLpxrJrWZu7vTbnTM4DtF0hl99gAY6Bf-T',
    liked: false
  },
  {
    title: 'Old School RuneScape',
    composer: ['James Hannigan'],
    releaseDate: '2001/01/04',
    coverArt: OSRS,
    link: 'PLADC838822026616E',
    liked: false
  },
  {
    title: 'Elden Ring',
    composer: ['Tsukasa Saito'],
    releaseDate: '2022/02/25',
    coverArt: EldenRing,
    link: 'PLRW80bBvVD3XMm0jsKtWOrxtTAcCociXJ',
    liked: false
  },
  {
    title: 'The Elder Scrolls V: Skyrim',
    composer: ['Jeremy Soule'],
    releaseDate: '2011/11/11',
    coverArt: Skyrim,
    link: 'PL8CB7943AB56938F8',
    liked: false
  },
  {
    title: 'Undertale',
    composer: ['Toby Fox'],
    releaseDate: '2015/09/15',
    coverArt: Undertale,
    link: 'PLpJl5XaLHtLX-pDk4kctGxtF4nq6BIyjg',
    liked: false
  },
  {
    title: 'Dark Souls',
    composer: ['Yuka Kitamura'],
    releaseDate: '2011/09/22',
    coverArt: DarkSouls,
    link: 'PLA69C1EEA3355844F',
    liked: false
  },
  {
    title: 'Valheim',
    composer: ['Patrik Jarlestam'],
    releaseDate: '2021/02/02',
    coverArt: Valheim,
    link: 'PLeKS-8gXxk9wFSB-ymp-o80cGi_sPYUk7',
    liked: false
  },
  {
    title: 'Deep Rock Galactic',
    composer: ['Sophus Alf Agerbæk-Larsen'],
    releaseDate: '2018/02/28',
    coverArt: DRG,
    link: 'PLggwHaWkdoiauENyO73WJM9Do6S9sxvhb',
    liked: false
  },
  {
    title: 'Rimworld',
    composer: ['Alistair Lindsay'],
    releaseDate: '2013/11/04',
    coverArt: Rimworld,
    link: 'PLh4Eme5gACZHaNOYGUuj_wFO0IR5x54Ad',
    liked: false
  },
  {
    title: 'Dark Souls 3',
    composer: ['Yuka Kitamura'],
    releaseDate: '2016/04/12',
    coverArt: DarkSouls3,
    link: 'PLpxrJrWZu7vQ9bPEEAJCkqlx8R4UaEHuy',
    liked: false
  },
  {
    title: 'The Witcher 3',
    composer: ['Marcin Przybyłowicz', 'Mikolai Stroinski'],
    releaseDate: '2015/05/19',
    coverArt: Witcher3,
    link: 'PL1ij2T_0HM3TnXecXuyqMgYUB5sHyY1ri',
    liked: false
  },
  {
    title: 'Star Wars Jedi: Fallen Order',
    composer: ['John Williams, Gordy Haab, Stephen Barton'],
    releaseDate: '2019/11/15',
    coverArt: SWJFO,
    link: 'PLDisKgcnAC4RUeGVROvlNSA0TAFDzejfC',
    liked: false
  },
  {
    title: 'De Blob',
    composer: ['John Guscott'],
    releaseDate: '2008/09/22',
    coverArt: deBlob,
    link: 'PL0601B42F3DEF954E',
    liked: false
  },
  {
    title: 'Sonic Adventure 2 Batlle',
    composer: ['Jun Senoue'],
    releaseDate: '2001/12/20',
    coverArt: SAB2,
    link: 'PLE9C66B83E9254B6D',
    liked: false
  },
  {
    title: 'Super Mario Galaxy 2',
    composer: ['Koji Kondo', 'Mahito Yokota', 'Ryo Nagamatsu'],
    releaseDate: '2010/05/23',
    coverArt: SuperMarioGalaxy2,
    link: 'PLC583980D7806298E',
    liked: false
  },
  {
    title: 'Risk of Rain 2',
    composer: ['Chris Christodoulou'],
    releaseDate: '2019/03/28',
    coverArt: RiskofRain2,
    link: 'PLLDf8Bnp1K1JPWia6_x8-1K2sVmXGYvJD',
    liked: false
  },
  {
    title: 'Super Mario 64',
    composer: ['Koji Kondo'],
    releaseDate: '1996/06/23',
    coverArt: SuperMario64,
    link: 'PLB4DC2508C4D0076D',
    liked: false
  },
  {
    title: 'Pikuniku',
    composer: ['Calum Bowen'],
    releaseDate: '2019/01/24',
    coverArt: Pikuniku,
    link: 'PLiQ603MjzdMyLjv194emPuqfG-nMaWLxF',
    liked: false
  },
  {
    title: 'Animal Crossing: New Horizons',
    composer: ['Kazumi Totaka', 'Yasuaki Iwata', 'Yumi Takahasi'],
    releaseDate: '2020/03/20',
    coverArt: ACNH,
    link: 'PLZq7Jn-XiIlskI0PI31tG_nSt8qPDjgsq',
    liked: false
  },
  {
    title: 'Franken',
    composer: ['Too many to count'],
    releaseDate: '2022/05/01',
    coverArt: Franken,
    link: 'PL2ishMFOEd73UM0lO7FmDZCRIyJVXXXGa',
    liked: false
  },
  {
    title: 'Chrono Trigger',
    composer: ['Yasunori Mitsuda', 'Nobuo Uematsu'],
    releaseDate: '1995/03/11',
    coverArt: ChronoTrigger,
    link: 'PLxJBkIRsvt_bNLMFm1-r5fhvOhD-g3MT0'
  },
  {
    title: 'The Legend of Zelda: A Link To The Past',
    composer: ['Koji Kondo'],
    releaseDate: '1991/11/21',
    coverArt: ALTTP,
    link: 'PL78C7BAABDB14E289'
  },
  {
    title: 'The Legend of Zelda',
    composer: ['Koji Kondo'],
    releaseDate: '1986/02/21',
    coverArt: TLOZ,
    link: 'PL71E19E7A8803384C'
  },
  {
    title: 'The Legend of Zelda: The Adventure of Link',
    composer: ['Akito Nakatsuka'],
    releaseDate: '1987/01/14',
    coverArt: ZeldaII,
    link: 'PL0ECC56A72000EB97'
  },
  {
    title: 'The Legend of Zelda: Majora\'s Mask',
    composer: ['Koji Kondo', 'Toru Minegishi'],
    releaseDate: '2000/04/27',
    coverArt: MajorasMask,
    link: 'PLlJacSJzs4lAnyKZqjAbnZ5vy35m1CR1S'
  },
  {
    title: 'The Legend of Zelda: Tears of the Kingdom',
    composer: ['Manaka Kataoka', 'Maasa Miyoshi', 'Masato Ohashi', 'Tsukasa Usui'],
    releaseDate: '2023/05/12',
    coverArt: TOTK,
    link: 'PLUEexOMzLsXhtPA9QiopYOVlYUmLIEFlV'
  },
  {
    title: 'Super Mario 3D World',
    composer: ['Koji Kondo', 'Mahito Yokota', 'Toru Minegishi', 'Yasuaki Iwata' ],
    releaseDate: '2013/11/22',
    coverArt: SuperMario3DWorld,
    link: 'PLAs1Kha_R9dLAPNbN6LnhIU91xwRfjFQr'
  },
  {
    title: 'New Super Mario Bros. Wii',
    composer: ['Ryo Nagamatsu', 'Shiho Fujii', 'Kenta Nagata'],
    releaseDate: '2009/11/12',
    coverArt: NewSuperMarioBrosWii,
    link: 'PLE46B81D4430D3556'
  },
  {
    title: 'Billy Hatcher and the Giant Egg',
    composer: ['Tomoya Ohtani', 'Mariko Nanba'],
    releaseDate: '2003/09/23',
    coverArt: BillyHatcher,
    link: 'PLvNp0Boas721WRc1QdsYvQFTTPOsKDgvl'
  },
  {
    title: 'Disney\'s Extreme Skate Adventure',
    composer: ['Allister','Audiovent','Basement Jaxx','G.R.I.T.S.','Jump5','Lil Romeo','Lisa W.','Newsboys','Reel Big Fish','Simon & Milo','Simple Plan','Smash Mouth','Trust Company'],
    releaseDate: '2003/09/03',
    coverArt: DisneysExtremeSkateAdventure,
    link: 'PLQP1XvbBcNQlIz2tlLyobvXqiBY7w6JDz'
  },
  {
    title: 'Crypt of the Necrodancer',
    composer: ['Danny Baranowsky'],
    releaseDate: '2015/04/23',
    coverArt: COTND,
    link: 'OLAK5uy_kCauGPYNXpDFaCqA6gbHSrwCY_QxFBTsc',
    liked: false
  },
  {
    title: 'GRIS',
    composer: ['Berlinist'],
    releaseDate: '2018/12/13',
    coverArt: GRIS,
    link: 'PLPUc0Mgs4Cs4D8BGffxUag083ek_mhZw2',
    liked: false
  },
  {
    title: 'OMORI',
    composer: ['Bo En', 'Pedro Silva', 'Slime Girls', 'Jami Lynne'],
    releaseDate: '2020/12/25',
    coverArt: OMORI,
    link: 'PLbANFjAlbtqLkcthrPJ7lqYcVTSwXr2L0',
    liked: false
  },
  {
    title: 'Monster Sanctuary',
    composer: ['Denis Sinner', 'Steven Melin', 'Peter Jones'],
    releaseDate: '2019/08/28',
    coverArt: MonsterSanctuary,
    link: 'PLYmIbXO7eIk3kgUIbNQBIRqItdJXHFGYz',
    liked: false
  },
  {
    title: 'Portal',
    composer: ['Kelly Bailey', 'Mike Morasky'],
    releaseDate: '2007/10/10',
    coverArt: Portal,
    link: 'PL407930F90CFF0B42',
    liked: false
  },
  {
    title: 'Portal 2',
    composer: ['Jonathan Coulton', 'Mike Morasky'],
    releaseDate: '2011/04/18',
    coverArt: Portal2,
    link: 'PL3772618E45A4088F',
    liked: false
  },
  {
    title: 'Rain World',
    composer: ['James Primate', 'Lydia Esrig'],
    releaseDate: '2017/03/28',
    coverArt: RainWorld,
    link: 'PLuX1dXf1Zu0kQ5RdXkLbHM0PFcyjzFIyU',
    liked: false
  },
  {
    title: 'SLUDGE LIFE 2',
    composer: ['Adam "Doseone" Drucker'],
    releaseDate: '2023/06/27',
    coverArt: SLUDGELIFE2,
    link: 'OLAK5uy_kQ_2nWDm86daQ4bARU-axm6hLMQiNrx8w',
    liked: false
  },
  {
    title: 'Ori and the Will of the Wisps',
    composer: ['Gareth Coker'],
    releaseDate: '2020/03/11',
    coverArt: OriWillWisps,
    link: 'OLAK5uy_nBYeVb6CVXHa29aOIQ51L9SRHx7VayA6I',
    liked: false
  },
  {
    title: 'Blasphemous',
    composer: ['Carlos Viola'],
    releaseDate: '2019/09/10',
    coverArt: Blasphemous,
    link: 'OLAK5uy_niQS3a2p6sPE5XXj3oCSxsp9gYXieSznY',
    liked: false
  },
  {
    title: 'The Elder Scrolls III: Morrowind',
    composer: ['Jeremy Soule'],
    releaseDate: '2002/05/01',
    coverArt: Morrowind,
    link: 'PL3BCE01906737B73D',
    liked: false
  },
  {
    title: 'The Elder Scrolls IV: Oblivion',
    composer: ['Jeremy Soule'],
    releaseDate: '2006/05/02',
    coverArt: Oblivion,
    link: 'PLXDG0TgiA_kWJPKS1jlN3MEceva-eZnyQ',
    liked: false
  },
  {
    title: 'Super Smash Bros. Brawl',
    composer: ['Nobuo Uematsu', 'Shogo Sakai'],
    releaseDate: '2008/01/31',
    coverArt: SuperSmashBroBrawl,
    link: 'PLAs1Kha_R9dLgsQqWKG-8UYD6HGOg6UO0',
    liked: false
  },
  {
    title: 'Super Smash Bros.',
    composer: ['Hirokazu Ando'],
    releaseDate: '1999/01/21',
    coverArt: SuperSmashBros,
    link: 'PL1Ldpec_OxhGp3Zm3zAx7RVHfEXmAT9h0',
    liked: false
  },
  {
    title: 'Super Smash Bros. Ultimate',
    composer: ['Hideki Sakamoto'],
    releaseDate: '2018/12/07',
    coverArt: SuperSmashBrosUltimate,
    link: 'PLPgWxeuDt5TjPQvVJ7BxoJefd55yiuy5g',
    liked: false
  },
  {
    title: 'Mario Kart: Double Dash!!',
    composer: ['Shinobu Tanaka', 'Kenta Nagata'],
    releaseDate: '2003/11/07',
    coverArt: MarioKartDoubleDash,
    link: 'PLfTJKMMJkrdNYiL5bwb7xbXSiyk9R5UVH',
    liked: false
  },
  {
    title: 'Kirby Air Ride',
    composer: ['Jun Ishikawa', 'Hirokazu Ando', 'Shogo Sakai', 'Tadashi Ikegami'],
    releaseDate: '2003/07/11',
    coverArt: KirbyAirRide,
    link: 'PLIgM4y_ZygHRwLVrgPiwQETZnyFDJwkR3',
    liked: false
  },
  {
    title: 'Unrailed! ',
    composer: ['Felix Barbarino', 'Mathilde Hoffmann'],
    releaseDate: '2019/09/09',
    coverArt: Unrailed,
    link: 'PLGm5B32f6qZa_9VxNUUhZ0cIbHkcXLlDL',
    liked: false
  },
  {
    title: 'Harry Potter: Quidditch World Cup',
    composer: ['Giuseppe Verdi', 'Jeremy Soule'],
    releaseDate: '2003/10/28',
    coverArt: HPQWC,
    link: 'PLVYyjhfI93m0AvRkrk1M5KSzavRS-S2hY',
    liked: false
  },
  {
    title: '1080° Avalanche',
    composer: ['James Phillipsen', 'Lawrence Schwedler'],
    releaseDate: '2003/11/28',
    coverArt: A1080,
    link: 'PLrXkjxA6bhd_BayG7QPTcaNEY4gNf0zME',
    liked: false
  },
  {
    title: 'Star Wars Battlefront II (Classic, 2005)',
    composer: ['John Williams', 'Jesse Harlin'],
    releaseDate: '2005/10/28',
    coverArt: SWBF2C,
    link: 'PL2Ps9TM89jDb78MxB2UVUwLPZFNxWjkQ-',
    liked: false
  },
  {
    title: 'FEZ',
    composer: ['Richard "Disasterpeace" Vreeland'],
    releaseDate: '2012/04/13',
    coverArt: FEZ,
    link: 'PLxbIk89I7mKv-Bdv2B57EtZ6zZ6st7A3x',
    liked: false
  },
  {
    title: 'N++',
    composer: ['Too many to name'],
    releaseDate: '2015/07/28',
    coverArt: N,
    link: 'PLC2JRJDXgYZCrSGRZ8SSMq3cmcqFP6Oc-',
    liked: false
  },
  {
    title: 'Overcooked',
    composer: ['Too many to name'],
    releaseDate: '2016/08/02',
    coverArt: Overcooked,
    link: 'PLc3u-w0cQfcX3zMscRZ04vYw-szNO9vjM',
    liked: false
  },
  {
    title: 'Papers, Please',
    composer: ['Lucas Pope'],
    releaseDate: '2013/08/08',
    coverArt: PapersPlease,
    link: 'PLg1a-oz64oqkwvCyg_LNDq23UpJ9fK2vd',
    liked: false
  },
  {
    title: 'Pizza Tower',
    composer: ['Ronan "Mr. Sauceman" de Castel', 'ClascyJitto (Frostix)'],
    releaseDate: '2023/01/26',
    coverArt: PizzaTower,
    link: 'PL60p5-zg-uLIn28dEckBclAU_wBXbDv8x',
    liked: false
  },
  {
    title: 'Midnight Club 3: Dub Edition',
    composer: ['Literally 100s'],
    releaseDate: '2005/04/11',
    coverArt: MidnightClub3,
    link: 'PL2upEFEREDHtu7neMze8Pg8k2cnpprP5u',
    liked: false
  },
  {
    title: 'Pokémon Gold / Silver',
    composer: ['Junichi Masuda', 'Go Ichinose', 'Morikazu Aoki', 'Braxton Burks'],
    releaseDate: '1999/10/21',
    coverArt: PokemonGVSV,
    link: 'PLtHuNRmYs3BWG-nx74eflw7xLD1ktPdvN',
    liked: false
  },
  {
    title: 'Dungeon Siege',
    composer: ['Jeremy Soule'],
    releaseDate: '2002/04/05',
    coverArt: DungeonSiege,
    link: 'PLA29174CEAE93841D',
    liked: false
  },
  {
    title: 'Realm of the Mad God',
    composer: ['Aiden "Spave" Strozzi'],
    releaseDate: '2011/06/20',
    coverArt: ROTMG,
    link: 'PLunh254bt1QtP8QqjQZR-zm-OjPiElnfD',
    liked: false
  },
  {
    title: 'Hunt: Showdown',
    composer: ['Port Sulphur Band'],
    releaseDate: '2018/02/22',
    coverArt: HuntShowdown,
    link: 'OLAK5uy_nxEX6AGdFfOc98gjmc4-7_7IoIDMfVfBg',
    liked: false
  },
  {
    title: 'Persona 5',
    composer: ['Shoji Meguro'],
    releaseDate: '2016/09/15',
    coverArt: Persona5,
    link: 'PLZuZrScKjWOMAEEBEGTmAGtmmlg6QY7bC',
    liked: false
  },
  {
    title: 'Vampire Survivors',
    composer: ['Daniele Zandara', 'Filippo Vicarelli', 'William Davies', 'Lorenzo Floro'],
    releaseDate: '2021/12/17',
    coverArt: VampireSurvivors,
    link: 'PLansmOwRinsD_9zZLQTQu-M37Mp0-56qJ',
    liked: false
  },
  {
    title: 'Dungeon Defenders',
    composer: ['Afshin Touighian'],
    releaseDate: '2010/12/15',
    coverArt: DungeonDefenders,
    link: 'OLAK5uy_nxZc3aSIa2LideBv4_-aVB56j0bADxRgk',
    liked: false
  },
  {
    title: 'Luigi\'s Mansion',
    composer: ['Shinobu Tanaka', 'Kazumi Totaka'],
    releaseDate: '2001/09/14',
    coverArt: LuigisMansion,
    link: 'PLUXEBUs17WcQQa_qjS5sCEoP0mzQrmIvB',
    liked: false
  },
  {
    title: 'Pajama Sam: No Need To Hide When It\'s Dark Outside',
    composer: ['Rick Rhodes', 'Danny Pelfrey', 'Jeremy Soule'],
    releaseDate: '1996/10/18',
    coverArt: PJ1,
    link: 'PLE9B91A1E309E0CDE',
    liked: false
  },
  {
    title: 'Kirby: Nightmare in Dream Land',
    composer: ['Shogo Sakai'],
    releaseDate: '2002/10/25',
    coverArt: KirbyNIDL,
    link: 'PLGCOc0AyFYQmxtUASbJOAyOuL7E5nsuvP',
    liked: false
  },
  {
    title: 'Grand Theft Auto V',
    composer: ['Tangerine Dream', 'The Alchemist', 'Woody Jackson', 'Oh No'],
    releaseDate: '2013/09/17',
    coverArt: GTAV,
    link: 'PLniAE5ZVzgJ72qmrW0BGgNzr2RspMx3Sg',
    liked: false
  },
  {
    title: 'DOOM (2016)',
    composer: ['Mick Gordon'],
    releaseDate: '2016/05/13',
    coverArt: Doom2016,
    link: 'PL3-Zr0Ym0Fgw9G6368AMfMKp9TR1ToaqR',
    liked: false
  },
  {
    title: 'Harry Potter and the Sorcerer\'s Stone',
    composer: ['Jeremy Soule'],
    releaseDate: '2001/10/15',
    coverArt: HarryPotterATSS,
    link: 'PLO4jlmGoc6uDCz5OICtnCvRzLv9GuoGnz',
    liked: false
  },
  {
    title: 'Among Us',
    composer: ['Marcus Bromander'],
    releaseDate: '2018/06/15',
    coverArt: AmongUs,
    link: 'PL8tKjHTEp8rta92Ez1KW-oNLXAHpFFJRa',
    liked: false
  },
  {
    title: 'Geometry Dash',
    composer: ['Waterflame', 'Step', 'Ocular Nebula', 'MDK', 'Kevin MacLeod', 'Hinkik', 'ForeverBound', 'F-777', 'DJVI', 'Dj-Nate'],
    releaseDate: '2013/08/13',
    coverArt: GeometryDash,
    link: 'PLnaJlq-zKc0WUXhwhSowwJdpe1fZumJzd',
    liked: false
  },
  {
    title: 'Titan Souls',
    composer: ['David Fenn'],
    releaseDate: '2015/04/14',
    coverArt: TitanSouls,
    link: 'PLhPp-QAUKF_jbFQRQSEM77jS1V-maKOgR',
    liked: false
  },
  {
    title: 'Destiny 2',
    composer: ['Michael Salvatori'],
    releaseDate: '2017/09/06',
    coverArt: Destiny2,
    link: 'OLAK5uy_mPuzRaebD9_KXZOK38GQnHSFI-VmsDkiY',
    liked: false
  },
  {
    title: 'Call of Duty: Modern Warfare 2',
    composer: ['Hans Zimmer', 'Lorne Balfe'],
    releaseDate: '2009/11/10',
    coverArt: CODMW2,
    link: 'PLVFu5mCs8PVjtwf9EAGG_FyxYEhryEUAd',
    liked: false
  },
  {
    title: 'Neverwinter Nights',
    composer: ['Jeremy Soule', 'Inon Zur'],
    releaseDate: '2002/06/18',
    coverArt: NeverwinterKnights,
    link: 'PLAMGM1hSRIUojpGz2TlXyBesgmgQnV3Hi',
    liked: false
  },
  {
    title: 'Kahoot!',
    composer: ['Alf Inge Wang'],
    releaseDate: '2013/03/11',
    coverArt: Kahoot,
    link: 'PL9FdqOfsB1tMElf7cOb8fKfoVge8KO7Yh',
    liked: false
  },
  {
    title: 'Metal Gear Solid V: The Phantom Pain',
    composer: ['Ludvig Forssell', 'Justin Burnett', 'Daniel James'],
    releaseDate: '2015/09/01',
    coverArt: MGSV,
    link: 'OLAK5uy_lg5OmHUB31vYcAa3QRz43tk255E2qfyao',
    liked: false
  },
  {
    title: 'Planetside 2',
    composer: ['Jeff Broadbent'],
    releaseDate: '2012/11/20',
    coverArt: Planetside2,
    link: 'PLIaOb07yB4uYhPAFCBrb3s6xplA5pN2lR',
    liked: false
  },
  {
    title: 'Guitar Hero 2',
    composer: ['A shit ton'],
    releaseDate: '2006/11/07',
    coverArt: GuitarHero2,
    link: 'PLhqQblXS9-ESFhD3BJUh_Gl3reVOvEBbw',
    liked: false
  },
  {
    title: 'Mirror\'s Edge',
    composer: ['Solar Fields'],
    releaseDate: '2008/11/11',
    coverArt: MirrorsEdge,
    link: 'PLD334A4E66B2E31EF',
    liked: false
  },
  {
    title: 'Wilkanoid',
    composer: ['https://www.wilkanoid.net/'],
    releaseDate: '2001',
    coverArt: Wilkanoid,
    link: 'PLw1u6xNhpPtYVfMG-KdPlHs1lpkEnoBAQ',
    liked: false
  },
  {
    title: 'Chex Quest',
    composer: ['	Andrew Benson'],
    releaseDate: '1996/10/31',
    coverArt: ChexQuest,
    link: 'PLjyLjyXJQJUPuQ-RnJgfqLQdYPJS70RhK',
    liked: false
  },
  {
    title: 'Halo 1: Combat Evolved',
    composer: ['Martin O\'Donnell', 'Michael Salvatori'],
    releaseDate: '2001/11/15',
    coverArt: Halo,
    link: 'PLE247806E68BCF2AC',
    liked: false
  },
  {
    title: 'Halo 3',
    composer: ['Martin O\'Donnell', 'Michael Salvatori'],
    releaseDate: '2007/09/25',
    coverArt: Halo3,
    link: 'PLVFu5mCs8PVi9gzKmuNwqDmRTMNYmHgDR',
    liked: false
  },
  {
    title: 'Battlefield 1',
    composer: ['Patrik Andrén', 'Johan Söderqvist'],
    releaseDate: '2016/10/21',
    coverArt: BF1,
    link: 'PLbZoYkHzfDbyNyPX-yg2BZaYyuqp4OzTL',
    liked: false
  },
  {
    title: 'Battlefield 3',
    composer: ['Johan Skugge', 'Jukka Rintamäki'],
    releaseDate: '2011/10/25',
    coverArt: BF3,
    link: 'PL67B22CDA707B1C01',
    liked: false
  },
  {
    title: 'Battlefield 4',
    composer: ['Johan Skugge', 'Jukka Rintamäki'],
    releaseDate: '2013/10/29',
    coverArt: BF4,
    link: 'PLv5gNyRtYVaPSbvyTh6L_Vq3CqcIoRvlt',
    liked: false
  },
  {
    title: 'Mass Effect',
    composer: ['Jack Wall', 'Richard Jacques', 'Sam Hulick', 'David Kates'],
    releaseDate: '2007/08/30',
    coverArt: MassEffect,
    link: 'PL5961C75C840F65AF',
    liked: false
  },
  {
    title: 'Mass Effect 2',
    composer: ['Jack Wall', 'Jimmy Hinson', 'Sam Hulick', 'David Kates'],
    releaseDate: '2010/01/26',
    coverArt: MassEffect2,
    link: 'PLE770F7CD93AC2FA8',
    liked: false
  },
  {
    title: 'Mass Effect 3',
    composer: ['Clint Mansell', 'Christopher Lennertz', 'Sascha Dikiciyan', 'Cris Velasco', 'Sam Hulick'],
    releaseDate: '2012/03/06',
    coverArt: MassEffect3,
    link: 'OLAK5uy_lnkGVSpOhuP0AUak836Q3H6AD9fow3epA',
    liked: false
  },
  {
    title: 'Mafia II',
    composer: ['Matúš Široký'],
    releaseDate: '2010/08/24',
    coverArt: MafiaII,
    link: 'PL93A9D028C1EE496A',
    liked: false
  },
  {
    title: 'Sonic Generations',
    composer: ['Jun Senoue'],
    releaseDate: '2011/11/01',
    coverArt: SonicGenerations,
    link: 'PL1A538347CCEAFE66',
    liked: false
  },
  {
    title: 'Sonic the Hedgehog',
    composer: ['Tomoya Ohtani', 'Hideaki Kobayashi', 'Takahito Eguchi', 'Mariko Nanba', 'Taihei Satō'],
    releaseDate: '2006/11/14',
    coverArt: Sonic06,
    link: 'PLvNp0Boas723k0aBhOnIBp1cYAp8S89i7',
    liked: false
  },
  {
    title: 'Bug Fables: The Everlasting Sapling',
    composer: ['Tristan Alric'],
    releaseDate: '2019/11/21',
    coverArt: BugFables,
    link: 'PL6_GDFAu6F50au-JZ1eWa0OnUA1k3cU60',
    liked: false
  },
  {
    title: 'Paper Mario: The Thousand-Year Door',
    composer: ['Yuka Tsujiyoko'],
    releaseDate: '2004/10/11',
    coverArt: PMTYD,
    link: 'PLZODI99P5wP9Qh_t4VNf4iRFEETG37Dhy',
    liked: false
  },
  {
    title: 'StarCraft II: Wings of Liberty',
    composer: ['Russell Brower', 'Neal Arcee', 'Jimmy Page', 'Allen Collins', 'Ronnie Van Zant', 'Iggy Pop', 'James Williamson', 'Lynyrd Skynyrd', 'Matt Samia', 'Audio Machine'],
    releaseDate: '2010/07/27',
    coverArt: StarcraftII,
    link: 'PL96A1D3209ECD24F0',
    liked: false
  },
  {
    title: 'Overwatch',
    composer: ['Derek Duke'],
    releaseDate: '2016/05/24',
    coverArt: Overwatch,
    link: 'PLW868HfwGgCP-9MbSNbHivXv8vEvowPc9',
    liked: false
  },
  {
    title: 'Overwatch 2',
    composer: ['Bill Petras', 'Arnold Tsang', 'Edouard Brenneisen', 'Adam Burgess'],
    releaseDate: '2022/10/04',
    coverArt: Overwatch2,
    link: 'PLw0-8MPb61nAw9ZE9kmIoJK5ty0Nv2fqq',
    liked: false
  },
  {
    title: 'Hearthstone',
    composer: ['Peter McConnell'],
    releaseDate: '2014/03/11',
    coverArt: Hearthstone,
    link: 'PLJaKbPlXXQon3TLMKh_Gm3rSKwPV9dm4w',
    liked: false
  },
  {
    title: 'Chibi-Robo!',
    composer: [' Hirofumi Taniguchi'],
    releaseDate: '2005/06/23',
    coverArt: ChibiRobo,
    link: 'PLD991FF764DE5F903',
    liked: false
  },
  {
    title: 'Wii Sports',
    composer: ['Kazumi Totaka'],
    releaseDate: '2006/11/19',
    coverArt: WiiSports,
    link: 'PLmHZT6dUyQ7NHGDeMFiVxR4mDMX5BlNrH',
    liked: false
  },
  {
    title: 'Wii Play',
    composer: ['Ryo Nagamatsu'],
    releaseDate: '2006/12/02',
    coverArt: WiiPlay,
    link: 'PLoqOgJJaN57YoM6lqgM-se13FoeG8MU-w',
    liked: false
  },
  {
    title: 'Marble Blast Gold',
    composer: ['Tim Clarke'],
    releaseDate: '2003/04/21',
    coverArt: MarbleBlastGold,
    link: 'PLKrbk0JUx3dvCX9j2v6kuRqLq1NnVLnwD',
    liked: false
  },
  {
    title: 'Rock of Ages',
    composer: ['Patricio Meneses'],
    releaseDate: '2011/08/31',
    coverArt: RockofAges,
    link: 'PLfuHaTW9nnaA-uqkRR3k5wmSMRB4H_bFa',
    liked: false
  },
  {
    title: 'Heroes of the Storm',
    composer: ['Glenn Stafford', 'Jason Hayes'],
    releaseDate: '2015/06/02',
    coverArt: HOTS,
    link: 'PL9WtwA1r0_Tbj_0SA__dt5gwQ4hjKCwbs',
    liked: false
  },
  {
    title: 'Brawlhalla',
    composer: ['Chris Rickwood'],
    releaseDate: '2014/04/30',
    coverArt: Brawlhalla,
    link: 'PLIqzfoiB9s0pW3KYvGDt-jn_ivzQ5Udx1',
    liked: false
  },
  {
    title: 'Half-Life',
    composer: ['Kelly Bailey'],
    releaseDate: '1998/11/19',
    coverArt: HalfLife,
    link: 'PLB61A980FC7A2E45A',
    liked: false
  },
  {
    title: 'Half-Life 2',
    composer: ['Kelly Bailey'],
    releaseDate: '2004/11/16',
    coverArt: HalfLife2,
    link: 'OLAK5uy_nGh5DFY7pJR6LFGCWjNSY6wc3cw8i9QlA',
    liked: false
  },
  {
    title: 'Half-Life Alyx',
    composer: ['Mike Morasky'],
    releaseDate: '2020/03/23',
    coverArt: HalfLifeAlyx,
    link: 'PLUAt2grZMdomRBA52R0qhwU2VZhfa9TPy',
    liked: false
  },
  {
    title: 'Baldur\'s Gate',
    composer: ['Michael Hoenig', 'Sam Hulick'],
    releaseDate: '1998/12/21',
    coverArt: BaldursGate,
    link: 'PLj0xfb_D-VPTVZKkqLw4Y1mIVt1MQtGFt',
    liked: false
  },
  {
    title: 'Baldur\'s Gate II',
    composer: ['Michael Hoenig', 'Howard Drossin'],
    releaseDate: '2000/09/21',
    coverArt: BaldursGate2,
    link: 'PLAMGM1hSRIUpLzBRQpG-Puu6c6mzkLfHI',
    liked: false
  },
  {
    title: 'Baldur\'s Gate III',
    composer: ['Borislav Slavov'],
    releaseDate: '2023/08/03',
    coverArt: BaldursGate3,
    link: 'PLi1CK-rsvz1Nfz83RMBp_9YaIgBWd0l9x',
    liked: false
  },
  {
    title: 'Little Nightmares',
    composer: ['Tobias Lilja', 'Christian Vasselbring'],
    releaseDate: '2017/04/28',
    coverArt: LittleNightmares,
    link: 'OLAK5uy_nWoT6aoq3ZCDjaqKFyYLtpFWhUzKq0F9Q',
    liked: false
  },
  {
    title: 'Little Nightmares II',
    composer: ['Tobias Lilja'],
    releaseDate: '2021/02/10',
    coverArt: LittleNightmaresII,
    link: 'PLYKQcyOJV5h6xZsUGzTprSTnMOSkuVBAa',
    liked: false
  },
  {
    title: 'Sunset Overdrive',
    composer: ['Boris Salchow'],
    releaseDate: '2014/10/28',
    coverArt: SunsetOverdrive,
    link: 'PL1soUfxlC8X1ruFqP28k14oFkfV_ehEld',
    liked: false
  },
  {
    title: 'South Park: The Stick Of Truth',
    composer: ['Jamie Dunlap'],
    releaseDate: '2014/03/04',
    coverArt: SouthParkTSOT,
    link: 'PLADajjvOG11EMg5gFLRMgyrwwsDNmOSAt',
    liked: false
  },
  {
    title: 'Journey',
    composer: ['Austin Wintory'],
    releaseDate: '2012/03/13',
    coverArt: Journey,
    link: 'OLAK5uy_nnbG74UhM0Oq1ZTYxHwTOsNYLpLF2Dof4',
    liked: false
  },
  {
    title: 'Hotline Miami',
    composer: ['M|O|O|N', 'Jasper Byrne', 'Perturbator', 'El Huervo', 'Scattle', 'Sun Araw', 'CoConuts', 'Eliott Berlin', 'Eirik Suhrke'],
    releaseDate: '2012/10/23',
    coverArt: HotlineMiami,
    link: 'PLUohqNDMJgb7qTjZoRlxu8wFtvmyTBzwY',
    liked: false
  },
  {
    title: 'EarthBound',
    composer: ['Keiichi Suzuki, Hirokazu Tanaka'],
    releaseDate: '1994/08/27',
    coverArt: EarthBound,
    link: 'PL1kU0pk5M3GCwzveqamX71sW3RmCzuXB5',
    liked: false
  },
  {
    title: 'Donkey Kong Country',
    composer: ['David Wise', 'Eveline Fischer', 'Robin Beanland'],
    releaseDate: '1994/11/21',
    coverArt: DKCountry,
    link: 'PL3qKGTUJcGDoHJGloGV4OdsqY-NNlxfvi',
    liked: false
  },
  {
    title: 'Donkey Kong Country 2: Diddy\'s Kong Quest',
    composer: ['David Wise'],
    releaseDate: '1995/11/21',
    coverArt: DKCountry2,
    link: 'PLFADADFD88F5B0565',
    liked: false
  },
  {
    title: 'Donkey Kong Country 3: Dixie Kong\'s Double Trouble!',
    composer: ['Eveline Fischer'],
    releaseDate: '1996/11/22',
    coverArt: DKCountry3,
    link: 'PLC_erJDYEnYugaLvTINKAHZy6PwRYtVwq',
    liked: false
  },
  {
    title: 'BioShock',
    composer: ['Garry Schyman'],
    releaseDate: '2007/08/21',
    coverArt: Bioshock,
    link: 'PL0140956A81E7F047',
    liked: false
  },
  {
    title: 'BioShock Infinite',
    composer: ['Garry Schyman'],
    releaseDate: '2013/03/26',
    coverArt: BioshockInfinite,
    link: 'PL3QZUm48uWnsdFakp3A2fI-NzmfH1jyQe',
    liked: false
  },
  {
    title: 'Medal of Honor',
    composer: ['Michael Giacchino'],
    releaseDate: '1999/10/12',
    coverArt: MedalofHonor,
    link: 'OLAK5uy_lof7DW0CSXDZZpxU__9jTFaqtljohi__M',
    liked: false
  },
  {
    title: 'L.A. Noire',
    composer: ['Andrew Hale, Simon Hale'],
    releaseDate: '2011/05/17',
    coverArt: LANoire,
    link: 'PL0B80EA34DA09E5C4',
    liked: false
  },
  {
    title: 'Everybody\'s Gone to the Rapture',
    composer: ['Jessica Curry'],
    releaseDate: '2015/08/11',
    coverArt: EGTTR,
    link: 'OLAK5uy_l6iGZoX3yE9QJ7b84s1Mocir0HJCH00II',
    liked: false
  },
  {
    title: 'Monument Valley 2',
    composer: ['Todd Baker'],
    releaseDate: '2017/06/05',
    coverArt: MonumentValley2,
    link: 'OLAK5uy_nZ2Eo2hz28Fk1DfxeIOig8zRZkqnTqYk4',
    liked: false
  },
  {
    title: 'Grand Fantasia',
    composer: ['IDK, couldn\'t find'],
    releaseDate: '2009/12/01',
    coverArt: GrandFantasia,
    link: 'PL9C33D800B59041A6',
    liked: false
  },
  {
    title: 'The Last of Us',
    composer: ['Gustavo Santaolalla'],
    releaseDate: '2013/06/14',
    coverArt: TLOU,
    link: 'OLAK5uy_m1EqJ-uUVzc9MzWMBHtQiuiOJrwQFcF-Q',
    liked: false
  },
  {
    title: 'The Last of Us Part II',
    composer: ['Gustavo Santaolalla', 'Mac Quayle'],
    releaseDate: '2020/06/19',
    coverArt: TLOUP2,
    link: 'OLAK5uy_mhIwtR6XH0mnRlNFtO7d474rcMkLvdfec',
    liked: false
  },
  {
    title: 'Katamari Damacy',
    composer: ['Yū Miyake', 'Asuka Sakai', 'Yuri Misumi', 'Hideki Tobeta', 'Yoshihito Yano'],
    releaseDate: '2004/03/18',
    coverArt: KatamariDamacy,
    link: 'PLyCSrz-kFGHBz_tVNTwW7WRE3sQ_ViFAs',
    liked: false
  },
  {
    title: 'NieR:Automata',
    composer: ['Keiichi Okabe', 'Kuniyuki "Koss" Takahashi', 'Keigo Hoashi'],
    releaseDate: '2017/02/23',
    coverArt: NieRAutomata,
    link: 'OLAK5uy_l--G5hZu15rmQoiNnHHGCARJ5xJePbWOM',
    liked: false
  },
  {
    title: 'Transistor',
    composer: ['Darren Korb'],
    releaseDate: '2014/05/20',
    coverArt: Transistor,
    link: 'OLAK5uy_kwxQAzEeXgrgbE9N_qc1lBgBH2127DLMY',
    liked: false
  },
  {
    title: 'Deadly Premonition',
    composer: ['Hiromi Mizutani', 'Riyou Kinugasa'],
    releaseDate: '2010/02/23',
    coverArt: DeadlyPremonition,
    link: 'PL7D5E203C4A465511',
    liked: false
  },
  {
    title: 'Shadow of the Colossus',
    composer: ['Kow Otani'],
    releaseDate: '2005/10/18',
    coverArt: SOTC,
    link: 'PLP305x78doI0efPOH7_cIlMXMR5WwMUOZ',
    liked: false
  },
  {
    title: 'Horizon Zero Dawn',
    composer: ['Joris de Man', 'The Flight', 'Niels van der Leest', 'Jonathan Williams'],
    releaseDate: '2017/02/28',
    coverArt: HorizonZeroDawn,
    link: 'PLVp84QtVnmRMOi3kQ94KUeZdi8KveV-P8',
    liked: false
  },
  {
    title: 'Horizon Forbidden West',
    composer: ['Joris de Man', 'The Flight', 'Niels van der Leest', 'Jonathan Williams'],
    releaseDate: '2022/02/18',
    coverArt: HorizonForbiddenWest,
    link: 'OLAK5uy_nzxthWeQFkB3TmTDfW4598_5eTIdyfjLo',
    liked: false
  },
  {
    title: 'Rocket League',
    composer: ['Mike Ault'],
    releaseDate: '2015/07/07',
    coverArt: RocketLeague,
    link: 'PL9zFrqswgf1lJoKufyxTeq5sRI6oC8jRO',
    liked: false
  },
  {
    title: 'Disco Elysium',
    composer: ['Sea Power'],
    releaseDate: '2019/10/15',
    coverArt: DiscoElysium,
    link: 'OLAK5uy_n9enLNuLjWTn67qj2aXP6FuJbX239vQdI',
    liked: false
  },
  {
    title: 'Red Dead Redemption',
    composer: ['Bill Elm', 'Woody Jackson'],
    releaseDate: '2010/05/18',
    coverArt: RDR,
    link: 'PL880D572DF1EA418B',
    liked: false
  },
  {
    title: 'Red Dead Redemption II',
    composer: ['Woody Jackson'],
    releaseDate: '2018/10/26',
    coverArt: RDR2,
    link: 'OLAK5uy_kj3Ql4kDDvzBzNYqEiiUU3Aked6gwDIy8',
    liked: false
  },
  {
    title: 'Life Is Strange',
    composer: ['Syd Matters'],
    releaseDate: '2015/01/30',
    coverArt: LifeisStrange,
    link: 'PLT1zim-_E9NEHF7GfkgInhE_glOTcK9S-',
    liked: false
  },
  {
    title: 'Bloodborne',
    composer: ['Yuka Kitamura', 'Tsukasa Saitoh', 'Cris Velasco', 'Ryan Amon', 'Nobuyoshi Suzuki', 'Michael Wandmacher'],
    releaseDate: '2015/03/24',
    coverArt: Bloodborne,
    link: 'PLKzg0DeoAjD-cGZOEspgvDGpFal8S2aS-',
    liked: false
  },
  {
    title: 'Sekiro',
    composer: ['Yuka Kitamura'],
    releaseDate: '2019/03/22',
    coverArt: Sekiro,
    link: 'PLKzg0DeoAjD_vDe9OcnBno9fIUHjAWh_O',
    liked: false
  },
  {
    title: 'Jet Set Radio',
    composer: ['Hideki Naganuma'],
    releaseDate: '2000/06/29',
    coverArt: JetSetRadio,
    link: 'PLE3VL-5NEtZiefRHaaTUGnU7JhOkbd3mT',
    liked: false
  },
  {
    title: 'NBA Street Vol. 3',
    composer: ['Tim McKenzie'],
    releaseDate: '2005/02/08',
    coverArt: NBASV3,
    link: 'PLL-sMa2lHYj0Ks4JmH8wcWTD2GgVlh91b',
    liked: false
  },
  {
    title: 'Metal: Hellsinger',
    composer: ['Two Feathers'],
    releaseDate: '2022/09/13',
    coverArt: MetalHellsinger,
    link: 'PLJQHL1SwXgq0m1LuxDGHDEWnBDFdYc2Vk',
    liked: false
  },
  {
    title: 'SSX Tricky ',
    composer: ['Jason Mizell'],
    releaseDate: '2001/11/05',
    coverArt: SSXTricky ,
    link: 'PLpV3Y829yI0dydG0rh8kTl4Z8ZAWzQPA3',
    liked: false
  },
  {
    title: 'Robocraft',
    composer: ['Simone Cicconi'],
    releaseDate: '2014/12/09',
    coverArt: Robocraft,
    link: 'PLVH6owJ1IV5TaxUq6GGhXndgzbGN2oHMn',
    liked: false
  },
  {
    title: 'Okami',
    composer: [' Masami Ueda', 'Hiroshi Yamaguchi'],
    releaseDate: '2006/04/20',
    coverArt: Okami,
    link: 'PL1AC584471FD4F040',
    liked: false
  },
  {
    title: 'Death Stranding',
    composer: ['Ludvig Forssell'],
    releaseDate: '2019/11/08',
    coverArt: DeathStranding,
    link: 'OLAK5uy_kVSocZST2zxfxkN-I4EhFzJQN2eX680Nw',
    liked: false
  },
  {
    title: 'Plants Vs. Zombies',
    composer: ['Laura Shigihara'],
    releaseDate: '2009/05/05',
    coverArt: PvsZ,
    link: 'OLAK5uy_lFED9JczEzg3HERiM1OeD_wctyVxSry_8',
    liked: false
  },
  {
    title: 'Professor Layton And The Curious Village',
    composer: ['Tomohito Nishiura'],
    releaseDate: '2007/02/15',
    coverArt: ProfLayton,
    link: 'OLAK5uy_kXNN-AmybeMxFfspUqHiyn3Am8UCA1mpc',
    liked: false
  },
  {
    title: 'Shovel Knight',
    composer: ['Jake Kaufman', 'Manami Matsumae'],
    releaseDate: '2014/06/26',
    coverArt: ShovelKnight,
    link: 'OLAK5uy_k05v8LNmgyOjEwmxWOIGYU8dHav4utetA',
    liked: false
  },
  {
    title: 'Crash Bandicoot',
    composer: ['Josh Mancell'],
    releaseDate: '1996/09/09',
    coverArt: CrashBandicoot,
    link: 'PLL-sMa2lHYj3YhYFZ38o-6VOu68I6vbom',
    liked: false
  },
  {
    title: 'Rayman Legends',
    composer: ['Christophe Heral'],
    releaseDate: '2013/08/29',
    coverArt: RaymanLegends,
    link: 'PLO4jlmGoc6uB_EhcOfiJWm-Q_4R25YNeT',
    liked: false
  },
  {
    title: 'Star Fox 64',
    composer: ['Koji Kondo', 'Hajime Wakai'],
    releaseDate: '1997/04/27',
    coverArt: StarFox64,
    link: 'PLFB2A17E7429EF5C3',
    liked: false
  },
  {
    title: 'Kirby: Squeak Squad',
    composer: ['Jun Ishikawa', 'Hirokazu Ando', 'Shogo Sakai', 'Tadashi Ikegami'],
    releaseDate: '2006/11/02',
    coverArt: KirbySS,
    link: 'PL2DFE837DFFD63588',
    liked: false
  },
  {
    title: 'Faster Than Light',
    composer: ['Ben Prunty '],
    releaseDate: '2012/09/14',
    coverArt: FTL,
    link: 'OLAK5uy_noimktdd_J6gG15slrltf0QGjhmArSEi0',
    liked: false
  },
  {
    title: 'Grim Fandango',
    composer: ['Peter McConnell'],
    releaseDate: '1998/10/30',
    coverArt: GrimFandango,
    link: 'PL307258AD9220B8AD',
    liked: false
  },
  {
    title: 'F-Zero X',
    composer: ['Taro Bando', 'Hajime Wakai'],
    releaseDate: '1998/07/14',
    coverArt: FZeroX,
    link: 'PLC0EEC2951F7155F3',
    liked: false
  },
  {
    title: 'Secret of Mana',
    composer: ['Hiroki Kikuta'],
    releaseDate: '1993/08/06',
    coverArt: SecretofMana,
    link: 'PLZtvIbH-NJ5QH71LdbIOkI-csYSMDeufj',
    liked: false
  },
  {
    title: 'Ico',
    composer: ['Michiru Ōshima, RAM'],
    releaseDate: '2001/09/25',
    coverArt: Ico,
    link: 'OLAK5uy_kLXN25mxRV9lEoZU50jBOXuL028uSZBjs',
    liked: false
  },
  {
    title: 'Dishonored',
    composer: ['Daniel Licht'],
    releaseDate: '2012/10/09',
    coverArt: Dishonored,
    link: 'OLAK5uy_nbYu5CtSUiNkPv7fFxHZ7L_uZmqJtvKTI',
    liked: false
  },
  {
    title: 'Knack',
    composer: ['Matthew Margeson', 'Wataru Hokoyama'],
    releaseDate: '2013/11/15',
    coverArt: Knack,
    link: 'PLcQE9TXdZV85T9K24HOKTK-l4l_L8CYE_',
    liked: false
  },
  {
    title: 'Knack II',
    composer: ['Anthony Willis'],
    releaseDate: '2017/09/05',
    coverArt: KnackII,
    link: 'PLw1u6xNhpPtb95bPojRQxgnGwzFJjYLMr',
    liked: false
  },
  {
    title: 'Cult of the Lamb',
    composer: ['Narayana "River Boy" Johnson'],
    releaseDate: '2022/08/11',
    coverArt: CultoftheLamb,
    link: 'PLA-UYFK3Q5SJ4pVNjHKSuP2G1RULJ5GUP',
    liked: false
  },
  {
    title: 'Stray',
    composer: ['Yann van der Cruyssen'],
    releaseDate: '2022/07/19',
    coverArt: Stray,
    link: 'OLAK5uy_n0G1FlMw7kSnhCT31OtvpVJsgltFWR3WQ',
    liked: false
  },
  {
    title: 'Furi',
    composer: ['Carpenter Brut', 'The Toxic Avenger', 'Waveshaper', 'Lorn', 'Danger', 'Scattle'],
    releaseDate: '2016/07/05',
    coverArt: Furi,
    link: 'PLtzah_dj5hUXk--M73cM1oq8twaKW_Axe',
    liked: false
  },
  {
    title: 'Riptide GP: Renegade',
    composer: ['Various'],
    releaseDate: '2016/07/26',
    coverArt: RiptideRenegade,
    link: 'PLVWLvQ1d5RcIoXoTjHVhGjD8gFTzKwkd7',
    liked: false
  },
  {
    title: 'Wario Ware: Smooth Moves',
    composer: ['Yasuhisa Baba', 'Masanobu Matsunaga', 'Naoko Mitome'],
    releaseDate: '2006/12/02',
    coverArt: WWSM,
    link: 'PL7443260FEE96175F',
    liked: false
  },
  {
    title: 'Demon\'s Souls',
    composer: ['Shunsuke Kida'],
    releaseDate: '2009/02/05',
    coverArt: DemonsSouls,
    link: 'PLDisKgcnAC4SkH6X7875k8i8vkb-YGbCb',
    liked: false
  },
  {
    title: 'Return of the Obra Dinn',
    composer: ['Lucas Pope'],
    releaseDate: '2018/10/18',
    coverArt: ROTOD,
    link: 'PLDisKgcnAC4Q2LR5-rsKC7L3i36VrB5gL',
    liked: false
  },
  {
    title: 'Rampage: Total Destruction',
    composer: ['Sam Powell', 'Jim Soldi'],
    releaseDate: '2006/04/24',
    coverArt: RampageTD,
    link: 'PLcR9eqoUS2TSU2Flow0eAG9UoDXEGFmkZ',
    liked: false
  },
  {
    title: 'TUNIC',
    composer: ['Terence "Lifeformed" Lee', 'Janice Kwan'],
    releaseDate: '2022/03/16',
    coverArt: TUNIC,
    link: 'OLAK5uy_k3qsTdWTGqWUz2opBT38pVVgo8aq1EayE',
    liked: false
  },
  {
    title: 'Dragon Age: Origins',
    composer: ['Inon Zur'],
    releaseDate: '2009/11/03',
    coverArt: DAOG,
    link: 'PL05E74F0AB06F62ED',
    liked: false
  },
  {
    title: 'Dragon Age II',
    composer: ['Inon Zur'],
    releaseDate: '2011/03/08',
    coverArt: DA2,
    link: 'PL33652123E000770E',
    liked: false
  },
  {
    title: 'Dragon Age Inquisition',
    composer: ['Trevor Morris'],
    releaseDate: '2014/11/18',
    coverArt: DAI,
    link: 'OLAK5uy_m2VwtTpUf1-4lXInr75iMrjYTB4QG4ljU',
    liked: false
  },
  {
    title: 'Pikmin',
    composer: ['Hajime Wakai'],
    releaseDate: '2001/10/26',
    coverArt: Pikmin,
    link: 'PL2D158F14D98DFA72',
    liked: false
  },
  {
    title: 'Pikmin 3',
    composer: ['Asuka Hayazaki', 'Atsuko Asahi', 'Hajime Wakai', 'Soshi Abe', 'Babi'],
    releaseDate: '2013/07/13',
    coverArt: Pikmin3,
    link: 'PLQWz7Y_QbVVybS_Zs-S8pjt67Nkilgfmv',
    liked: false
  },
  {
    title: 'Pikmin 4',
    composer: ['Kenta Nagata', 'Asuka Hayazaki', 'Soshi Abe'],
    releaseDate: '2023/07/21',
    coverArt: Pikmin4,
    link: 'PLVk7-4qy_GtSw2l41UPWNs-pjNsmuFsJU',
    liked: false
  },
  {
    title: 'Diablo 1',
    composer: ['Matt Uelmen'],
    releaseDate: '1996/12/31',
    coverArt: Diablo,
    link: 'PL4C4D2047A5B1423D',
    liked: false
  },
  {
    title: 'Diablo II',
    composer: ['Matt Uelmen'],
    releaseDate: '2000/06/28',
    coverArt: DiabloII,
    link: 'PLD80CC3FEBE323161',
    liked: false
  },
  {
    title: 'Diablo III',
    composer: ['Joey Lawrence', 'Laurence Juber', 'Glenn Stafford', 'Russell Brower', 'Derek Duke', 'Edo Guidotti'],
    releaseDate: '2012/05/15',
    coverArt: DiabloIII,
    link: 'PLBF5FDBFF7A2EB549',
    liked: false
  },
  {
    title: 'Diablo Immortal',
    composer: ['Adam Skorupa'],
    releaseDate: '2022/06/02',
    coverArt: DiabloImmortal,
    link: 'PLtRbaVX8K5g9RplezOXW_Ptyol5SrEVDt',
    liked: false
  },
  {
    title: 'World of Warcraft: Wrath of the Lich King',
    composer: ['Glenn Stafford', 'Russell Brower', 'Derek Duke'],
    releaseDate: '2008/11/13',
    coverArt: WOWWOTLK,
    link: 'PL6FE892C35C4D403D',
    liked: false
  },
  {
    title: 'World of Warcraft: Cataclysm',
    composer: ['David Arkenstone', 'Glenn Stafford', 'Neal Acree', 'Russell Brower', 'Derek Duke'],
    releaseDate: '2010/12/07',
    coverArt: WOWC,
    link: 'PL4D33EA76FC37FAC5',
    liked: false
  },
  {
    title: 'World of Warcraft: The Burning Crusade',
    composer: ['Matt Uelmen', 'Russell Brower', 'Derek Duke'],
    releaseDate: '2007/01/16',
    coverArt: WOWBC,
    link: 'PLE2A521E34FEBDF15',
    liked: false
  },
  {
    title: 'World of Warcraft: Mists of Pandaria',
    composer: ['Jeremy Soule', 'Neal Acree', 'Russell Brower'],
    releaseDate: '2012/09/25',
    coverArt: WOWMOP,
    link: 'PLJ8iSLqfEQcJ13w4jIG_iqfl2YmkEaUS5',
    liked: false
  },
  {
    title: 'World of Warcraft: Warlords of Draenor',
    composer: ['Eímear Noone', 'Neal Acree', 'Russell Brower', 'Sam Cardon', 'Clint Bajakian', 'Craig Stuart Garfinkle', 'Edo Guidotti'],
    releaseDate: '2014/11/13',
    coverArt: WOWWOD,
    link: 'PLqCHJ3vtnzhZFEkyxe5wEscvJGE2OPmR9',
    liked: false
  },
  {
    title: 'World of Warcraft: Legion',
    composer: ['Neal Acree', 'Russell Brower', 'Clint Bajakian'],
    releaseDate: '2016/04/30',
    coverArt: WOWL,
    link: 'PLqCHJ3vtnzhbiAJbQAjJzEv8zjXU3SZDx',
    liked: false
  },
  {
    title: 'World of Warcraft: Battle For Azeroth',
    composer: ['Glenn Stafford', 'Neal Acree', 'Sam Cardon', 'David Arkenstone', 'Clint Bajakian', 'Derek Duke'],
    releaseDate: '2018/08/14',
    coverArt: WOWBFA,
    link: 'PLx1vPNizuOeluMFys3qLJQ7vbyiaMwDQM',
    liked: false
  },
  {
    title: 'World of Warcraft: Shadowlands',
    composer: ['Neal Acree', 'Glenn Stafford'],
    releaseDate: '2020/11/23',
    coverArt: WOWS,
    link: 'PLoNbcLaOVGYnwE1e2cKpW-KsWMrK2z0qf',
    liked: false
  },
  {
    title: 'World of Warcraft: Dragonflight',
    composer: ['David Arkenstone'],
    releaseDate: '2022/12/31',
    coverArt: WOWD,
    link: 'PLFO8yg49CxgsANMDk2iiXmUY7v3GLk3ie',
    liked: false
  },
  {
    title: 'Animal Crossing',
    composer: ['Kazumi Totaka', 'Toru Minegishi', 'Kenta Nagata'],
    releaseDate: '2001/04/14',
    coverArt: AC,
    link: 'PLnM4qYUiXrxwIBTp2lhyh_U5kOUaJsif3',
    liked: false
  },
  {
    title: 'Animal Crossing: Wild World',
    composer: ['Kazumi Totaka', 'Asuka Hayazaki'],
    releaseDate: '2005/11/23',
    coverArt: ACWW,
    link: 'PLv2IGBk7nw2NaCeY04oTpcBbg9hno5Y3M',
    liked: false
  },
  {
    title: 'Animal Crossing: New Leaf',
    composer: ['Kazumi Totaka', 'Manaka Kataoka', 'Atsuko Asahi'],
    releaseDate: '2012/11/08',
    coverArt: ACNL,
    link: 'PLHI6ByT8e1z8YsEuZOgbePMkMpjFkcUb2',
    liked: false
  },
  {
    title: 'Uncharted: Drake\'s Fortune',
    composer: ['Greg Edmonson'],
    releaseDate: '2007/11/19',
    coverArt: UnchartedDF,
    link: 'PL8KyWN8ADp2hHsLBJ4WGrDv3vcsXx8JCr',
    liked: false
  },
  {
    title: 'Uncharted 2: Among Thieves',
    composer: ['Greg Edmonson'],
    releaseDate: '2009/10/13',
    coverArt: Uncharted2AT,
    link: 'OLAK5uy_ku6IMR-AU1eNcQAq2Lb6BEQJDbdYBFVZ0',
    liked: false
  },
  {
    title: 'Uncharted 3: Drake\'s Deception',
    composer: ['Greg Edmonson'],
    releaseDate: '2011/11/01',
    coverArt: Uncharted3DD,
    link: 'OLAK5uy_kPT1x-0hS8gCkFoMtQPJAHXFN8fz5TQzA',
    liked: false
  },
  {
    title: 'Uncharted 4: A Thief\'s End',
    composer: ['Henry Jackman'],
    releaseDate: '2016/05/10',
    coverArt: Uncharted4ATE,
    link: 'OLAK5uy_mRaDFL88K-FGy_xq9Yxub7vjKWKwZKxKk',
    liked: false
  },
  {
    title: 'Kingdom Hearts',
    composer: ['Yoko Shimomura'],
    releaseDate: '2002/03/14',
    coverArt: KH,
    link: 'PLYDdhHVGYrjKGZURwkooz8jAfChYBHmH6',
    liked: false
  },
  {
    title: 'Kingdom Hearts II',
    composer: ['Yoko Shimomura'],
    releaseDate: '2005/12/22',
    coverArt: KH2,
    link: 'PLDD1C236CA6E094FC',
    liked: false
  },
  {
    title: 'Kingdom Hearts III',
    composer: ['Yoko Shimomura', 'Takeharu Ishimoto', 'Tsuyoshi Sekito'],
    releaseDate: '2019/01/25',
    coverArt: KH3,
    link: 'OLAK5uy_lf2A541jfw2RjrIEKLcy6cyBc-x7KSMwg',
    liked: false
  },
  {
    title: 'Hitman: Codename 47',
    composer: ['Jesper Kyd'],
    releaseDate: '2000/10/19',
    coverArt: HitmanC47,
    link: 'PL2B56C4B3F3DECB3C',
    liked: false
  },
  {
    title: 'Hitman',
    composer: ['Niels Bye Nielsen'],
    releaseDate: '2016/03/11',
    coverArt: Hitman,
    link: 'PLaYFg67nHr4a0ts7tQPV5OIZ_Ue1OZLDr',
    liked: false
  },
  {
    title: 'Hitman 2 ',
    composer: ['Niels Bye Nielson '],
    releaseDate: '2018/11/13',
    coverArt: Hitman2,
    link: 'PLjlEOIaWZ5GprHqaFSHCbV2Myr_wRkHz_',
    liked: false
  },
  {
    title: 'Hitman 3',
    composer: ['Niels Bye Nielsen'],
    releaseDate: '2021/01/20',
    coverArt: Hitman3,
    link: 'PL3LlwEQPbNYCS_lLkaTBt6zZ8nXJt1MMo',
    liked: false
  },
  {
    title: 'The Elder Scrolls I: Arena',
    composer: ['	Eric Heberling'],
    releaseDate: '1994/03/25',
    coverArt: Arena,
    link: 'PL-ACqe7xAdNjgUTQ579C-J7WPGszZWdU4',
    liked: false
  },
  {
    title: 'The Elder Scrolls II: Daggerfall',
    composer: ['Eric Heberling'],
    releaseDate: '1996/09/20',
    coverArt: Daggerfall,
    link: 'PLThaHokEd5yYkXCQYSCQvphoXnKwz1li-',
    liked: false
  },
  {
    title: 'League of Legends',
    composer: ['Sebastien Najand', 'A lot of others'],
    releaseDate: '2009/10/27',
    coverArt: LOL,
    link: 'PLIwv-CmVkeJzcX3oSMEGb_1qfGzbEGiNy',
    liked: false
  },
  {
    title: 'The Messenger',
    composer: ['Rainbowdragoneyes'],
    releaseDate: '2018/08/30',
    coverArt: TheMessenger,
    link: 'PLjggKtuoASrJgkoI-NSAUSc881YwIucAN',
    liked: false
  },
  {
    title: 'Final Fantasy XIV: Heavensward',
    composer: ['Masayoshi Soken'],
    releaseDate: '2015/06/23',
    coverArt: FFXIVHeavensward,
    link: 'PLkMSELXhmxAPUTK7Xf44deMC1Oeu3AtaO',
    liked: false
  },
  {
    title: 'Final Fantasy XIV: Stormblood',
    composer: ['Masayoshi Soken', 'Nobuo Uematsu'],
    releaseDate: '2017/06/20',
    coverArt: FFXIVStormblood,
    link: 'PLKzg0DeoAjD_YFPcmnGtA3BVEX3WxI93n',
    liked: false
  },
  {
    title: 'Final Fantasy XIV: Shadowbringers',
    composer: ['Masayoshi Soken'],
    releaseDate: '2019/07/02',
    coverArt: FFXIVShadowbringers,
    link: 'PLQybuz6NcNnF1_dt7K0aFDzJQTCoMwC4O',
    liked: false
  },
  {
    title: 'Final Fantasy XIV: Endwalker',
    composer: ['Masayoshi Soken'],
    releaseDate: '2021/12/07',
    coverArt: FFXIVEndwalker,
    link: 'PLQybuz6NcNnH2CPJEFfn1iDl76aTBiSoP',
    liked: false
  },
  {
    title: 'Skullgirls',
    composer: ['Michiru Yamane', 'Vincent Diamante', 'Brenton Kossak', 'Blaine McGurty'],
    releaseDate: '2012/04/10',
    coverArt: Skullgirls,
    link: 'PLQliFjHLzZXWaTyNLG0T_iX_AVNlUYElm',
    liked: false
  },
  {
    title: 'Hotline Miami 2: Wrong Number',
    composer: ['M|O|O|N', 'Jasper Byrne', 'Perturbator', 'El Huervo', 'Scattle', 'Sun Araw', 'CoConuts', 'Eliott Berlin', 'Eirik Suhrke','many more...'],
    releaseDate: '2015/03/10',
    coverArt: HotlineMiami2,
    link: 'PLURBlBqSzVmH8ZxBwnIiQgTDi7hbcA89k',
    liked: false
  },
  {
    title: 'Metal Gear Rising: Revengeance',
    composer: ['Jamie Christopherson'],
    releaseDate: '2013/02/19',
    coverArt: MGRR,
    link: 'PL1tFM4hAoC6VGoSUQBWXdykwzjT96cYeb',
    liked: false
  },
  {
    title: 'Devil May Cry 5',
    composer: ['Ko-Ta'],
    releaseDate: '2019/03/08',
    coverArt: DevilMayCry5,
    link: 'PLN_ISN--vzwJCGP9fei2IkRNdjfUQFWrk',
    liked: false
  },
  {
    title: 'Wildfrost',
    composer: ['Paul Zimmermann'],
    releaseDate: '2023/04/12',
    coverArt: Wildfrost,
    link: 'PLMiq2y6PsMyU2cM2394e3HfYDb77L6WKq',
    liked: false
  },
  {
    title: 'Dead Cells',
    composer: ['Yoann Laulan'],
    releaseDate: '2017/05/10',
    coverArt: DeadCells,
    link: 'PLcmMH0GjHwRnRujz_4y1oTSyxZ4PQPMnp',
    liked: false
  },
  {
    title: 'Team Fortress 2',
    composer: ['Mike Morasky'],
    releaseDate: '2007/10/10',
    coverArt: TF2,
    link: 'PL4E9ABD7FF1106BD3',
    liked: false
  },
  {
    title: 'Enter the Gungeon',
    composer: ['Doseone'],
    releaseDate: '2016/04/05',
    coverArt: EnterTheGungeon,
    link: 'PL5PflUvz4QrUeyTd0CtT2wOX9veTQv92I',
    liked: false
  },
  {
    title: 'Mother 3',
    composer: ['Shogo Sakai'],
    releaseDate: '2006/04/20',
    coverArt: Mother3,
    link: 'PLYix9WqFpAhGNYgUWepepxfDAtAFe_adr',
    liked: false
  }
]

const App = () => {
  /**
   * useStates for handling rerendering and data storage
   */
  const [Database, setDatabase] = useState(baseDatabase)
  const [likedDatabase, setLikedDatabase] = useState(JSON.parse(localStorage.getItem("Liked")))
  const [selectedSong, setSelectedSong] = useState(Database[Math.floor(Math.random() * Database.length)])
  const [listView, setListView] = useState(true)
  const [whichFilter, setWhichFilter] = useState(true)
  const [alphabeticOrder, setAlphabeticOrder] = useState(true)
  const [dateOrder, setDateOrder] = useState(true)
  const [reload, setReload] = useState(true)
  const [windowHeight, setWindowHeight] = useState(1000)
  const [mobile, setMobile] = useState(false)
  const [listInfo, setListInfo] = useState(false)
  const [searchFilter, setSearchFilter] = useState(-1)
  const [youtubeWidth, setYoutubeWidth] = useState(400)

  
  /**
   * useEffect to handle all on-load functions,
   * Sets likes, window size, and checks if on mobile or not
   */
  useEffect(() => {
    // On page load, switches to grid view if on a mobile device
    const onPageLoad = () => {
      const mql = window.matchMedia("(pointer:coarse)");
      handleOnLoadYoutubeWidth()
      if(mql.matches) {
        setMobile(true)
        setWhichFilter(true)
        setAlphabeticOrder(false)
        Filtering(true)
        handleView()
        
      }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  /**
   *  On page load, checks for liked list from local storage
   */ 
  if ("Liked" in localStorage) {
    // If one is found, applys the liked osts
    const oldLiked = JSON.parse(localStorage.getItem("Liked"))
    // Loops through and grabs all the listed osts, liking them
    for (let i = 0; i < Database.length; i++) {
      const inArray = oldLiked.find((element) => element === Database[i].title)
      if (inArray !== undefined) {
        Database[i].liked = true
      }
    }
  } else {
    // No previous liked local storage
    localStorage.setItem("Liked", "[]")
  }
  
  /**
   * Handles the button press of a Like button
   */
   const handleLiked = ( name, ost ) => {
    addToLiked(name, ost)
  }

  /**
   * Handles when an ost has been liked or unliked
   */
   const addToLiked = ( name, ost ) => {
    // Pulls current liked array from local storage
    const oldLiked = JSON.parse(localStorage.getItem("Liked"))
    setLikedDatabase(oldLiked)
    const inArray = oldLiked.find((element) => element === name)

    if (inArray === undefined) {
      // Adding an Ost to liked
      oldLiked.push(name)
      localStorage.setItem("Liked", JSON.stringify(oldLiked))
      
      // Update fronted with the new liked ost
      ost.liked = true
      setReload(!reload)
    } else {
      // Remove Ost from liked
      const indexOf = oldLiked.indexOf(name)
      oldLiked.splice(indexOf, 1)
      localStorage.setItem("Liked", JSON.stringify(oldLiked))

      // Update fronted with the removed ost
      ost.liked = false
      setReload(!reload)
    }
  }

  /**
   * Picks a random ost from your liked library on the Sidebar.
   */
   const handleRandomLiked = () => {
    // If no liked ost, it doesn't do anything
    if (likedDatabase.length === 0) {
      return(0)
    } else {
      // Grabs a random ost from the list and puts it as the selected one
      const randomOst = likedDatabase[Math.floor(Math.random() * likedDatabase.length)]
      const test = Database.filter(ost => ost.title === randomOst)
      setSelectedSong(test[0])
    }
  }

  /**
   * Picks a random ost from the library on the Sidebar.
   */
   const handleRandom = () => {
    setSelectedSong(Database[Math.floor(Math.random() * Database.length)])
  }

  /**
   * Sets the clicked on ost for the Sidebar.
   */
  const handleSelected = ( title ) => {
    const test = Database.filter(ost => ost.title === title)
    setSelectedSong(test[0])
  }

  /**
   * Handles the printing of the metadata of the current ost
   */
  const handleInfo = () => {
    setDatabase(Database)
    setListInfo(!listInfo)
  }

  /**
   * Toggles which view the app is in.
   */
  const handleView = () => {
    setListView(!listView)
  }

  /**
   * Sets the filter to Alphabet mode.
   */
  const handleAlphabeticOrder = () => {
    setAlphabeticOrder(!alphabeticOrder)
    setDateOrder(null)
    setWhichFilter(true)
    Filtering(true)
  }

  /**
   * Sets the filter to Release Date mode.
   */
  const handleDateOrder = () => {
    setDateOrder(!dateOrder)
    setAlphabeticOrder(null)
    setWhichFilter(false)
    Filtering(false)
  }

  /**
   * Handles actually filtering depeneding on filter mode.
   */
  const Filtering = (whichFilter) => {
    if (whichFilter) {
      if (alphabeticOrder) {
        Database.sort((a, b) => {
          if (a.title < b.title) {return -1;}return 0;
        })
      } else {
        Database.sort((a, b) => {
          if (a.title > b.title) {return -1;}return 0;
        })}
    } else {
      if (dateOrder) {
        Database.sort((a, b) => {
          if (a.releaseDate < b.releaseDate) {return -1;}return 0;
        })
      } else {
        Database.sort((a, b) => {
          if (a.releaseDate > b.releaseDate) {return -1;}return 0;
        })}
    }
  }

  /**
   * Adjusts the embeded video height dyanmically with the size of the screen
   */
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight)
  });

  /**
   * Handles search box input change, updating search function
   */
  const handleInputChange =  (event) => {
    handleSearch(event.target.value.toLowerCase())
  }

  /**
   * Search function to search for Ost by name
   */
  const handleSearch = (search) => {
    // Determines search filter
    if (searchFilter === -1) {
      // Search by Title
      Database.sort((a, b) => 
        b.title.toLowerCase().includes(search) - a.title.toLowerCase().includes(search))
    } else if (searchFilter === 0) {
      // Search by Composer
      Database.sort((a, b) => 
        b.composer.toString().toLowerCase().includes(search) - a.composer.toString().toLowerCase().includes(search))
    } else {
      Database.sort((a, b) => 
        b.releaseDate.toString().includes(search) - a.releaseDate.toString().includes(search))
    }
    setReload(!reload)
  }

  /**
   * Handles toggling the search filter
   */
  const handleSearchFilter = () => {
    if (searchFilter === -1) {
      setSearchFilter(0)
    } else if (searchFilter === 0) {
      setSearchFilter(1)
    } else {
      setSearchFilter(-1)
    }
  }

  /**
   * Handles changed the youtube embed width
   */
  const handleYoutubeWidth = () => {
    if (youtubeWidth === 400) {
      setYoutubeWidth(470)
      localStorage.setItem("Expanded", "470")
    } else {
      setYoutubeWidth(400)
      localStorage.setItem("Expanded", "400")
      
    }
  }

  /**
   * Handles on load setting the youtube width
   */
  const handleOnLoadYoutubeWidth = () => {
    if ("Expanded" in localStorage) {
      setYoutubeWidth(parseInt(localStorage.getItem("Expanded")))
    } else {
      setYoutubeWidth(400)
    }
  }

  /**
   * HTML React moment
   */
  return (
    <body className='Page'>
      <section className='Sidebar' style={{width: youtubeWidth+35}}>
        <img 
          className='SidebarCover' 
          src={selectedSong.coverArt} 
          alt={selectedSong.title} 
          width={youtubeWidth}
          height={youtubeWidth}
        />
        {mobile ? (
          <Button
            className='MobileRandomButton'
            img={DiceIcon} 
            onClick={handleRandom} 
            width='30' 
            height='30'
            title='Random Ost'
          />
        ) : (
          <LikeButton 
            className='SidebarButton'
            reload={reload} 
            img={selectedSong.liked ? (FilledLike) : (EmptyLike)} 
            width='30' 
            height='30'
            onClick={() => handleLiked(selectedSong.title, selectedSong)}
            title={selectedSong.liked ? ("Unlike") : ("Like")}
          />
        )}
        <marquee 
          className='Scroller' 
          width="70%" 
          scrolldelay="200" 
        >
          {selectedSong.title}
        </marquee>
        <Button 
          className='InfoButton'
          onClick={handleInfo}
          img={InfoIcon} 
          width='30' 
          height='30'
          title={listInfo ? ("Hidden Ost Information") : ("Show Ost Information")}
        />
        {listInfo ? (
          <InfoBox selectedSong={selectedSong}/>
        ) : (
          <></>
        )}
        <YoutubeEmbed 
          embedId={selectedSong.link} 
          className='YoutubeEmbed' 
          height={listInfo ? (windowHeight/2.5) : (windowHeight/2)}
          width={youtubeWidth}
        />
        {youtubeWidth === 400 ? (
          <button
          className='ExpandYoutube'
          onClick={handleYoutubeWidth}
          >
            Expand Player
          </button>
        ) : (
          <button
          className='RetrackYoutube'
          onClick={handleYoutubeWidth}
          >
            Retrack Player
          </button>
        )}
        <p className="copyright">Version 1.2</p>
        <p className="copyright">Copyright 2023 by Michael Valois. All Rights Reserved.</p>
      </section>
      <section className='Header'>
        <Button 
          className='IconButton' 
          img={listView ? (GridIcon) : (HamburgerIcon)} 
          onClick={handleView} 
          width='30' 
          height='30'
          title={listView ? ("View: Grid") : ("View: List")} 
        />
        <Button 
          className='IconButton' 
          img={whichFilter ? alphabeticOrder ? (AlphabetFirstIcon) : (AlphabetLastIcon) : AlphabetNoneIcon}
          onClick={handleAlphabeticOrder}
          width='30' 
          height='30'
          title={whichFilter ? alphabeticOrder ? ("Sort: Z-A") : ("Sort: A-Z") : "Sort: By Alphabetic Order"}
        />
        <Button 
          className='IconButton' 
          img={whichFilter ? DateNoneIcon : dateOrder ? (DateLastIcon) : (DateFirstIcon)}
          onClick={handleDateOrder}
          width='30' 
          height='30'
          title={whichFilter ? ("Sort: By Date") : dateOrder ? ("Sort: Newest") : ("Sort: Oldest")}
        />
        <Button 
          className='IconButton' 
          img={searchFilter === -1 ? (TitleIcon) : searchFilter === 0 ? (ComposerIcon) : (ReleaseDateIcon)}
          onClick={handleSearchFilter}
          width='30' 
          height='30'
          title={searchFilter === -1 ? ("Search Filter: By Title") : searchFilter === 0 ? ("Search Filter: By Composer") : ("Search Filer: By Release Date:\n     'yyyy/mm/dd' or 'yyyy'")}
        />
        <input 
          className='SearchBar' 
          type='text' 
          id="search"
          name="search"
          onChange={handleInputChange}
          placeholder='Search' 
          autoComplete='off'
          size='26'
        />
        <Button 
          className='SettingIconButton' 
          img={SettingsIcon} 
          width='30' 
          height='30'
        />
      </section>
      <section className='Main'>
        {listView ? (
          <section className='ListMain'>
            <section>
              <Button
                className='LikedIconButton'
                img={DiceIcon} 
                onClick={handleRandom} 
                width='30' 
                height='30'
                title='Random Ost'
              />
              <h2>Library ({Database.length})</h2>
              <Library 
                Library={Database}
                reload={reload}
                handleSelected={handleSelected}
                handleLiked={handleLiked}
              />
            </section>
            <section>
              <Button
                className='LikedIconButton'
                img={DiceIcon}
                onClick={handleRandomLiked} 
                width='30' 
                height='30'
                title='Random Liked Ost'
              />
              <h2>Liked ({likedDatabase.length})</h2>
              <Liked 
                Library={Database}
                reload={reload}
                handleSelected={handleSelected}
                handleLiked={handleLiked}
              />
            </section>
          </section>
        ) : (
          <Grid  
            Library={Database} 
            handleSelected={handleSelected}
          />
        )}
      </section>
    </body>
  )
}

export default App