import React from 'react'
import { Button } from './Button'

export const Grid = ({
    Library,
    handleSelected
    }) => {
    return (
        <section className='Library'>
            {Library.map(Ost => {
                return (
                    <Button
                        className='GridButton' 
                        imgClassName='GridImg' 
                        onClick={() => handleSelected(Ost.title)} 
                        img={Ost.coverArt} 
                        width='100' 
                        height='100'
                        title={Ost.title}
                    />
                )
            })}
        </section>
    )
}