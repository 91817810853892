import React from 'react'
import { ButtonRow } from './ButtonRow'
import { LikeButton } from './LikeButton'
import EmptyLike from '../icons/EmptyLike.png'
import FilledLike from '../icons/FilledLike.png'

export const Row = ({
    Library,
    reload,
    img,
    title,
    handleSelected,
    handleLiked
}) => {
  // useState variable that just makes sure each row is reloaded when an ost is liked/unliked
  if(reload) {}
  
  return (
    <li className="Row">
        <ButtonRow className='RowButton' imgClassName='RowImg' onClick={() => handleSelected(title)} title={title} img={img} width='70' height='70'/>
        <LikeButton  
          className='RowLike' 
          onClick={() => handleLiked(title, Library)}
          img={Library.liked ? (FilledLike) : (EmptyLike)} 
          width='30' 
          height='30'
          title={Library.liked ? ("Unlike") : ("Like")}
        />
        <p className='RowTitle'>{title}</p>
    </li>
  )
}