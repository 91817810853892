import React from 'react'

export const Button = ({ 
    className,
    imgClassName, 
    onClick, 
    img, 
    width, 
    height,
    title
    }) => {
    return (
        <button className={className} onClick={onClick} title={title}>
            <img className={imgClassName} src={img} alt={title} width={width} height={height}/>
        </button>
    )
}