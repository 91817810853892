import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, className, height, width }) => {
    return (
        <div className={className}>
            <iframe
                width={width}
                height={height}
                src={`https://www.youtube.com/embed/videoseries?list=${embedId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                frameborder="0"
                title="Embedded youtube playlist"
            />
        </div>
)};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;